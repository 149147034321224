import React, { Component } from 'react';
import './footer.scss';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Visible } from 'react-grid-system';
import { connect } from 'react-redux';

interface FooterLayoutProps {
  settings: any,
}

const mapStateToProps = (state: any) => {
  return {
    settings: state.settings,
  }
}

function FooterLayout(props: FooterLayoutProps) {
  return (
    <div className="footer">
      <div className="page">
        <Container fluid={true} className="footer-content">
          <Visible sm md lg xl>
            <Row>
              <Col className="footer-column" xs={4} sm={4} md={4} lg={4}>
                <div className="item">
                  <Link to="/provider">Нийлүүлэгч</Link>
                </div>
                <div className="item">
                  <Link to="/terms">Хүргэлтийн нөхцөл</Link>
                </div>
                <div className="item">
                  <Link to="/terms">Үйлчилгээний нөхцөл</Link>
                </div>
              </Col>

              <Col className="footer-column" xs={4} sm={4} md={4} lg={4}>
                <div className="item">
                  <Link to="/contact">Холбогдох</Link>
                  <p>
                    <a href={`tel:${props.settings.supportPhone}`}>
                      <i className="icon icon-phone"></i>
                        +976 {props.settings.supportPhone}
                    </a>
                  </p>
                  <p>
                    <i className="icon icon-envelop">&nbsp;</i>
                    <a href={`mailto:${props.settings.supportEmail}`}>{props.settings.supportEmail}</a>
                  </p>
                </div>
              </Col>

              <Col className="footer-column" xs={4} sm={4} md={4} lg={4}>
                <div className="item">
                  Хаяг:
                    <p style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <span className="icon icon-location2" style={{ paddingTop: '5px' }}>&nbsp; </span>
                    <span>
                      Наадамчдийн зам 77<br />
                      Яармаг, 5-р хороо<br />
                      Хан-Уул дүүрэг<br />
                      Улаанбаатар хот<br />
                    </span>
                  </p>
                </div>
              </Col>
            </Row>
          </Visible>

          <Visible xs>
            <Col className="footer-column" xs={12} sm={12} md={12}>
              <div className="item">
                <Link to="/provider">Нийлүүлэгч</Link>
              </div>
              <div className="item">
                <Link to="/terms">Үйлчилгээний нөхцөл</Link>
              </div>
              <div className="item">
                Хаяг:
                  <p style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <span className="icon icon-location2" style={{ paddingTop: '5px' }}>&nbsp; </span>
                  <span>
                    Наадамчдийн зам 77,<br />
                    Яармаг, 5-р хороо,<br />
                    Хан-Уул дүүрэг,<br />
                    Улаанбаатар хот,<br />
                  </span>
                </p>
              </div>
              <div className="item">
                Холбогдох:
                    <p>
                  <a href={`tel:${props.settings.supportPhone}`}>
                    <i className="icon icon-phone"></i>&nbsp;
                      +976 {props.settings.supportPhone}
                      </a>
                </p>
                <p>
                  <i className="icon icon-envelop">&nbsp;</i>
                  <a href={`mailto:${props.settings.supportEmail}`}>{props.settings.supportEmail}</a>
                </p>
              </div>
            </Col>
          </Visible>
        </Container>

        <Container fluid={true} className="copyright">
          <Col>{props.settings.PRO_CONFIG_NAME} © 2024</Col>
        </Container>
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(FooterLayout)