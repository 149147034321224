import * as React from "react";
import { Link, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { Form, Button, Icon } from "semantic-ui-react";
import store from '../../store';
import './signup.scss';

interface SignupProps {
  modalView: boolean
}

interface SignupState {
  redirect: string,
  errorMsg: string,
  companyName: string,
  companyRegister: string,
  ownName: string,
  parentName: string,
  email: string,
  password: string,
  passwordMatch: string,
  phone: string,
  loading: boolean,
  view: string
}

const Section = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const MyForm = styled.div`
    max-width: 600px;
    margin-top: 20px;
`;

const FormGroup = styled.div`
    margin-top: 20px;
`;

// create a password schema
class ClientSignUpComponent extends React.Component<SignupProps, SignupState> {
  constructor(props: any) {
    super(props);

    this.state = {
      redirect: "",
      errorMsg: "",
      companyName: "",
      companyRegister: "",
      ownName: "",
      parentName: "",
      email: "",
      password: "",
      passwordMatch: "",
      phone: "",
      loading: false,
      view: 'USER',
    };

    this.onEmailChange = this.onEmailChange.bind(this);
    this.onPhoneChange = this.onPhoneChange.bind(this);
    this.onCompanyNameChange = this.onCompanyNameChange.bind(this);
    this.onOwnNameChange = this.onOwnNameChange.bind(this);
    this.onParentNameChange = this.onParentNameChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onPasswordMatchChange = this.onPasswordMatchChange.bind(this);
    this.onRegisterChange = this.onRegisterChange.bind(this);
  }

  onEmailChange(e?: any, v?: any) {
    this.setState({ email: e.target.value });
  }

  onPhoneChange(e?: any, v?: any) {
    this.setState({ phone: e.target.value });
  }

  onOwnNameChange(e?: any, v?: any) {
    this.setState({ ownName: e.target.value });
  }

  onCompanyNameChange(e?: any, v?: any) {
    this.setState({ companyName: e.target.value });
  }

  onRegisterChange(e?: any, v?: any) {
    this.setState({ companyRegister: e.target.value });
  }

  onParentNameChange(e?: any, v?: any) {
    this.setState({ parentName: e.target.value });
  }

  onPasswordChange(e?: any, v?: any) {
    this.setState({ password: e.target.value });
  }

  onPasswordMatchChange(e?: any, v?: any) {
    this.setState({ passwordMatch: e.target.value });
  }

  validate(): boolean {
    if (this.state.view === 'COMPANY' && !this.state.companyName) {
      this.setState({ errorMsg: "Байгууллагын нэр оруулна уу" })
      return false;
    }
    if (this.state.view === 'COMPANY' && !this.state.companyRegister) {
      this.setState({ errorMsg: "Байгууллагын регистр оруулна уу" })
      return false;
    }
    if (!this.state.parentName) {
      this.setState({ errorMsg: "Та овогоо оруулна уу" })
      return false;
    }
    if (!this.state.ownName) {
      this.setState({ errorMsg: "Та өөрийн нэрээ оруулна уу" })
      return false;
    }
    if (!this.state.email) {
      this.setState({ errorMsg: "Та имэйл оруулна уу" })
      return false;
    }
    if (!this.validateEmail()) {
      this.setState({ errorMsg: "Имэйл буруу форматтай байна" })
      return false;
    }
    if (!this.state.phone) {
      this.setState({ errorMsg: "Та гар утасны дугаар оруулна уу" })
      return false;
    }
    if (!this.validatePhone()) {
      this.setState({ errorMsg: "Та гар утасны дугаараа шалгаж үзнэ үү" })
      return false;
    }
    if (!this.state.password) {
      this.setState({ errorMsg: "Та нууц үг оруулна уу" })
      return false;
    }
    if (!this.validatePassword()) {
      this.setState({ errorMsg: "Нууц үг 6 тэмдэгтээс урт байх хэрэгтэй" })
      return false;
    }
    if (!this.state.passwordMatch) {
      this.setState({ errorMsg: "Та нууц үгээ дахин оруулна уу" })
      return false;
    }
    if (this.state.password !== this.state.passwordMatch) {
      this.setState({ errorMsg: "Дахин оруулсан нууц үг өөр байна" })
      return false;
    }
    return true;
  }

  validateEmail() {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(this.state.email).toLowerCase());
  }

  validatePhone() {
    var re = /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/;
    return re.test(String(this.state.phone).toLowerCase());
  }

  validatePassword() {
    return this.state.password.length > 6;
    // return /[A-Z]/.test(this.state.password) &&
    //   /[a-z]/.test(this.state.password) &&
    //   /[0-9]/.test(this.state.password) &&
    //   /[^A-Za-z0-9]/.test(this.state.password) &&
    //   this.state.password.length > 4;
  }

  handleSubmit = () => {
    if (!this.validate()) {
      return;
    }

    this.setState({ errorMsg: "", loading: true })

    let payload;
    
    if (this.state.view === 'USER') {
      payload = {
        parentName: this.state.parentName,
        ownName: this.state.ownName,
        phone: this.state.phone,
        email: this.state.email,
        password: this.state.password
      }
    }
    else {
      payload = {
        companyName: this.state.companyName,
        companyRegister: this.state.companyRegister,
        parentName: this.state.parentName,
        ownName: this.state.ownName,
        phone: this.state.phone,
        email: this.state.email,
        password: this.state.password
      };
    }

    fetch(process.env.REACT_APP_CHEF_API + 'signup', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    })
    .then(res => {
      if (res.ok) {
        res.json().then((resJson: any) => {
          store.dispatch({
            type: 'clientToken',
            clientToken: resJson.token
          })
          localStorage.setItem('clientToken', resJson.token)
          this.getClient(resJson.token);
        });
      }
      else {
        res.json().then(resJson => {
          this.setState({ errorMsg: resJson.errorMsg, loading: false })
        })
      }
    })
    .catch(err => {
      console.error(err)
      this.setState({ errorMsg: "Сүлжээнд алдаа гарлаа", loading: false })
    })
  };

  getClient(token: string) {
    fetch(process.env.REACT_APP_CHEF_API + 'client', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    })
    .then((res: any) => {
        if (res.ok) {
            res.json().then((resJson: any) => {
                store.dispatch({
                    type: 'authenticateClient',
                    client: resJson.client
                })
                let redirectUrl = store.getState().redirectUrl || "/";
                console.log("Redirecting to: " + redirectUrl)
                this.setState({ redirect: redirectUrl })
                store.dispatch({ type: "redirectUrl", data: "" })
            })
        }
    })
    .catch((err: any) => {
        console.error(err)
        this.setState({ errorMsg: "Сүлжээнд алдаа гарлаа", loading: false })
    })
  }

  scrollToForm = () => {
    let element = document.getElementById("signup-form");
    if (element) {
      element.scrollIntoView();
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <div className={this.props.modalView ? "" : "page signup-page margin-top-50"}>
        <Section>
          <MyForm>
            <Form id="signup-form" autoComplete="off">
              { this.props.modalView ? null :
              <div className="flex-div width-100 border-radius">
                <div className={"tab" + (this.state.view === 'USER' ? " active" : "")} onClick={() => this.setState({view: 'USER'})}>ХУВЬ ХҮН</div>
                <div className={"tab" + (this.state.view === 'COMPANY' ? " active" : "")} onClick={() => this.setState({view: 'COMPANY'})}>БАЙГУУЛЛАГА</div>
              </div>
              }
              { this.state.view === 'COMPANY' ?
                <React.Fragment>
                  <FormGroup>
                    <input type="text" required placeholder='Байгууллагын нэр' value={this.state.companyName} 
                      onChange={this.onCompanyNameChange} />
                  </FormGroup>
                  <FormGroup>
                    <input type="text" required placeholder='Байгууллагын регистр' value={this.state.companyRegister} 
                      onChange={this.onRegisterChange} />
                  </FormGroup>
                  <FormGroup>
                    <p>Харилцах хүний мэдээлэл:</p>
                  </FormGroup>
                </React.Fragment>
                : null
              }

              <FormGroup>
                <input type="text" required placeholder='Овог' value={this.state.parentName} onChange={this.onParentNameChange} />
              </FormGroup>

              <FormGroup>
                <input type="text" required placeholder='Нэр' value={this.state.ownName} onChange={this.onOwnNameChange} />
              </FormGroup>

              <FormGroup>
                <input type="email" required placeholder='Имэйл' value={this.state.email} onChange={this.onEmailChange} />
              </FormGroup>

              <FormGroup>
                <input type="text" required placeholder='Гар утас' value={this.state.phone} autoComplete="off" onChange={this.onPhoneChange} />
              </FormGroup>

              <FormGroup>
                <p className="sm-grey-text">Нууц үг 6 тэмдэгтээс урт байх хэрэгтэй</p>
                <input type="password" required placeholder='Нууц үг' value={this.state.password} onChange={this.onPasswordChange} />
              </FormGroup>

              <FormGroup>
                <input type="password" required placeholder='Нууц үг дахин оруулна уу' value={this.state.passwordMatch} onChange={this.onPasswordMatchChange} />
              </FormGroup>
              
              { this.state.errorMsg ? <React.Fragment>
                  <br />
                  <FormGroup>
                    <Icon name="warning sign" color='red'/>
                    {this.state.errorMsg}
                  </FormGroup>
                </React.Fragment> : null
              }
              <br />
              <Button className="width-100" color='red' onClick={this.handleSubmit}>Бүртгүүлэх</Button>
              <br />
              <br />
              { this.props.modalView ? null :
                <Link to="/login" className="flex-div">
                  <Button color='grey' onClick={this.handleSubmit} disabled={this.state.loading}>Нэвтрэх</Button>
                </Link>
              }
            </Form>
          </MyForm>
        </Section>
      </div>
    );
  }
}

export default ClientSignUpComponent;