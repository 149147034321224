import React, { useEffect } from 'react';
import './basket-sidebar.scss';
import store from '../../store';
import { connect } from 'react-redux';
import { Icon, Button, Label, Modal } from 'semantic-ui-react';
import ClientLoginComponent from '../authentication/login';
import ClientSignUpComponent from '../authentication/signup';
import { ClientModel } from '../../entities/client.model';
import { Link, useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';

interface BasketSidebarProps {
  basketItems: any
  totalAmount: number
  client: ClientModel
}

const mapStateToProps = (state: any) => {
  let basketItems: any [] = [];
  let totalAmount: number = 0;

  if (state.products&& state.basket && state.basket.quantityById) {
    state.products.forEach((product: any) => {
      if (state.basket.quantityById[product.id] !== null && state.basket.quantityById[product.id] !== undefined) {
        // exists in the basket
        product['count'] = state.basket.quantityById[product.id];
        basketItems.push(product);
        totalAmount += product.count * (product.saleActive? product.salePrice : product.price);
      }
    });
  }

  return {
    basketItems: basketItems,
    totalAmount: totalAmount,
    client: state.client
  }
}

function BasketSidebar(props: BasketSidebarProps) {

  let history = useHistory()
  const [open, setOpen] = React.useState(false)
  const [modalAction, setModalAction] = React.useState("")

  useEffect(() => {
    if (props?.client?.id && modalAction !== "") {
      document.body.classList.remove("no-scroll");
      history.push('/client/checkout')
      store.dispatch({
        type: 'basketOpen',
        value: false
      });
      setOpen(false)
      setModalAction("")
    }
  }, [props.client])

  const closeSidebar = () => {
    store.dispatch({
      type: 'basketOpen',
      value: false
    });
    document.body.classList.remove("no-scroll");
  }

  const removeItemFromBasket = (product: any) => {
    store.dispatch({
      type: 'removeItemFromBasket',
      product: product
    });
  }

  const onItemCountChange = (index: number, e: any) => {
    if (!e.target.value || e.target.value === "0") {
      store.dispatch({
        type: 'updateBasketItemCount',
        product: props.basketItems[index],
        count: "0"
      })
      return;
    }
    let count: number = Number(e.target.value);
    if (count > props.basketItems[index].warehouseCount) {
      return;
    }
    store.dispatch({
      type: 'updateBasketItemCount',
      product: props.basketItems[index],
      count: count
    })
  }

  const onItemCountBlur = (index: number, e: any) => {
    if (!e.target.value || e.target?.value === "0") {
      store.dispatch({
        type: 'removeItemFromBasket',
        product: props.basketItems[index]
      })
    }
  }

  const continueAction = () => {
    if (props?.client?.id) {
      document.body.classList.remove("no-scroll");
      history.push('/client/checkout')
      store.dispatch({
        type: 'basketOpen',
        value: false
      });
    }
    else {
      setOpen(true);
      setModalAction("LOGIN")
    }
  }

  const itemIncreaseCount = (index: number) => {
    let count = props.basketItems[index].count;
    if (count >= props.basketItems[index].warehouseCount) {
      return;
    }
    count += 1;
    store.dispatch({
      type: 'updateBasketItemCount',
      product: props.basketItems[index],
      count: count
    })
  }

  const itemDecreaseCount = (index: number) => {
    let count = props.basketItems[index].count;
    if (count < 1) {
      count = 0;
    }
    else {
      count -= 1;
    }
    store.dispatch({
      type: 'updateBasketItemCount',
      product: props.basketItems[index],
      count: count
    })
  }

  return (
    <div className="basket-sidebar">
      <div className="black-layer" onClick={() => closeSidebar()}></div>
      <div className="sidebar-background"></div>

      <div className="sidebar-container">
        <div className="sidebar-inner">
          <div className="close-section" onClick={() => closeSidebar()}>
            <i className="icon icon-cross"></i>
            <span className="close">Хаах</span>
          </div>
          <div className="sidebar-content">
          {
            props.basketItems.map((product: any, pindex: number) => {
              return <div className="basket-item" key={"basket-item-" + pindex}>
                <div className="product-details">
                  <Link to={"/product/" + product.slug}>
                    <img className="product-image" src={product.imageSmUrl} onClick={() => closeSidebar()}/>
                  </Link>
                  <div className="product-right">
                    <div className="product-name">
                      <div className="name">
                        <Link to={"/product/" + product.slug} onClick={() => closeSidebar()}>{ product.name }</Link>
                      </div>
                      <div className="control">
                        { product.size && product.unit ? <span className="price-per">{ product.size + " " + product.unit }</span> : null }
                        <span className="remove" onClick={() => removeItemFromBasket(product)}>
                          <Icon name="trash"/>
                          устгах
                        </span>
                      </div>
                    </div>
                    <div className="product-amount">
                      <div className="product-count">
                        <Icon name="caret down" onClick={() => itemDecreaseCount(pindex)}></Icon>
                        <input type="number" value={product.count}
                          onChange={(e) => onItemCountChange(pindex, e)}
                          onBlur={(e) => onItemCountBlur(pindex, e)}/>
                        <Icon name="caret up" onClick={() => itemIncreaseCount(pindex)}></Icon>
                      </div>
                      <div className="stock-line">{ product.warehouseCount <= product.count ? ("Боломжит үлдэгдэл: " + product.warehouseCount) : ""}</div>
                    </div>
                  </div>
                </div>
                <div className="product-price">
                  <NumberFormat displayType={'text'} value={ (product.saleActive ? product.salePrice : product.price) } suffix="₮" thousandSeparator={true}/>
                  &nbsp;x {product.count} =
                  <NumberFormat displayType={'text'} value={ (product.saleActive ? product.salePrice : product.price) * product.count } suffix="₮" thousandSeparator={true}/>
                </div>
              </div>
            })
          }
          </div>
        </div>
        <div className="check-out-line">
            <div className="continue ui labeled button">
              <Button className={"continue-1 ui button" + (props.totalAmount === 0 ? " disabled" : "")} onClick={() => continueAction()}>Үргэлжлүүлэх</Button>
              <Label className={"continue-2 ui basic left pointing red label" + (props.totalAmount === 0 ? " disabled" : "")}>
                <NumberFormat displayType={'text'} value={props.totalAmount} suffix="₮" thousandSeparator={true}/>
              </Label>
            </div>
          </div>
      </div>
    
      <Modal basic={true} dimmer="blurring"
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}>

          <Modal.Header>
            { modalAction === 'REGISTER' ? <Button onClick={ () => setModalAction("LOGIN") } color="red">Нэвтрэх</Button>
              :
              modalAction === 'LOGIN' ? <Button onClick={ () => setModalAction("REGISTER") } color="red">Бүртгүүлэх</Button>
              :
              null
            }
            
          </Modal.Header>
          <Modal.Content>
            { modalAction === 'REGISTER' ? <ClientSignUpComponent modalView={true}></ClientSignUpComponent>
              :
              modalAction === 'LOGIN' ? <ClientLoginComponent modalView={true}></ClientLoginComponent>
              :
              null
            }
          </Modal.Content>

      </Modal>
    </div>
  )
}

export default connect(mapStateToProps)(BasketSidebar)