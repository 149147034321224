import React from 'react';
import { Col, Container } from 'react-grid-system';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import BasketSidebar from '../../components/basket-sidebar/basket-sidebar';
import SearchInputComponent from '../../components/search-page/search-input.component';
import SidebarCategories from '../../components/sidebar-categories/sidebar-categories';
import TopCategories from '../../components/top-categories/top-categories';
import { ClientModel } from '../../entities/client.model';
import { ProviderUserModel } from '../../entities/provider.user.model';
import store from '../../store';
import './layout.scss';

interface TopLayoutProps {
  sidebarOpen: boolean,
  basketOpen: boolean,
  basketCount: number,
  basketValue: number,
  client: ClientModel,
  provider: ProviderUserModel,
  settings: any
}

interface TopLayoutState {
  redirect: string,
  clientMenuOpen: boolean
}

const mapStateToProps = (state: any) => {
  let basketCount = 0;

  if (state.basket) {
    for(let productId of state.basket.addedIds) {
      basketCount += state.basket.quantityById[productId];
    }
  }

  return {
    sidebarOpen: state.sidebarOpen,
    basketOpen: state.basketOpen,
    basketCount: basketCount,
    basketValue: state.basket.totalAmount,
    client: state.client,
    provider: state.provider,
    settings: state.settings,
  }
}

class MainLayout extends React.Component<TopLayoutProps, TopLayoutState> {

  public closingMenu = false;

  constructor(props: any) {
    super(props)
    this.state = {
      redirect: "",
      clientMenuOpen: false
    }
    // document.addEventListener("click", this.handleClick);
  }

  toggleMenu() {
    this.setState({ clientMenuOpen: !this.state.clientMenuOpen })
  }

  showClientMenu() {
    this.closingMenu = false;
    this.setState({ clientMenuOpen: true })
  }

  getOutOfClientMenu() {
    this.closingMenu = true;
    setTimeout(() => {
      if (this.closingMenu) {
        this.setState({ clientMenuOpen: false })
      }
    }, 200);
  }

  openSidebar = () => {
    store.dispatch({
      type: 'sidebarOpen',
      value: true
    });
    
    document.body.classList.add("no-scroll");
  }

  openBasketSidebar = () => {
    if (this.props.basketValue === 0) {
      return;
    }
    store.dispatch({
      type: 'basketOpen',
      value: true
    });
    
    document.body.classList.add("no-scroll");
  }

  search = () => {
    window.open("/search");
  }

  logout() {
    localStorage.removeItem('clientToken')
    store.dispatch({ type: 'logout' })
  }

  logoutProvider() {
    localStorage.removeItem('providerToken')
    store.dispatch({ type: 'logoutProvider' })
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <div className="main-layout" id="main-layout">
        <div className="upper-container">
          <div className="upper-section">

                <div className="left section">
                  <div className="burger-menu" onClick={() => this.openSidebar()}>
                    <span className="icon icon-menu"></span>
                  </div>
                  <div className="left-content">
                    { this.props.settings?.facebookMsgLink ? <a href={this.props.settings.facebookMsgLink} className="btn btn-40 btn-trans-white btn-chat" target="_blank" rel="noopener noreferrer">
                      <span id="svg" style={{color: '#fff', height:24, content: `url(${process.env.PUBLIC_URL + "/messenger.svg"})`}}></span>
                      <span className="text xs-hidden">&nbsp;Facebook-р холбогдох</span>
                    </a> : null}
                  </div>
                </div>

                <div className="right section">
                  { this.props.provider?.id ?
                  <React.Fragment>
                    <div className="right-item">
                      <Link to="/provider/warehouse">
                        <span className="own-name">
                          Сайн байна уу, {this.props.provider.ownName}
                        </span>
                      </Link>
                    </div>
                    <div className="right-item margin-left-20 cursor" onClick={() => this.logoutProvider()}>
                      <Icon name="stop circle outline" size="large"/>
                      <span className="menu-text">Вебсайтаас гарах</span>
                    </div>
                  </React.Fragment>
                  :
                  <React.Fragment>
                  <div className="controls">
                    { this.props.client?.id ? <React.Fragment>
                      <div className="client-menu" onClick={() => this.toggleMenu()} onMouseEnter={() => this.showClientMenu()} onMouseLeave={() => this.getOutOfClientMenu()}>
                        {/* <div>Сайн байна уу,</div> */}
                        <span className="parent-name">{ this.props.client.parentName?.substr(0,1) }.</span>
                        <span className="own-name">
                          {this.props.client.ownName}
                        </span>

                        { this.state.clientMenuOpen ?
                          <div className="client-dropdown">
                            <div className="dropdown-item">
                              <Link to="/client/account" onClick={() => this.setState({clientMenuOpen: false})}>
                                <span className="menu-icon">
                                  <Icon name="user circle" size='large'/>
                                </span>
                                <span className="menu-text">Миний аккаунт</span>
                              </Link>
                            </div>
                            <div className="dropdown-item">
                              <Link to="/client/company" onClick={() => this.setState({clientMenuOpen: false})}>
                                <span className="menu-icon">
                                  <Icon name="building outline" size='large'/>
                                </span>
                                <span className="menu-text">Байгууллага</span>
                              </Link>
                            </div>
                            <div className="dropdown-item">
                              <Link to="/client/saved-products" onClick={() => this.setState({clientMenuOpen: false})}>
                                <Icon name="heart"/>
                                <span className="menu-text">Хадгалсан бараа</span>
                              </Link>
                            </div>
                            <div className="dropdown-item">
                              <Link to="/client/orders" onClick={() => this.setState({clientMenuOpen: false})}>
                                <span className="menu-icon">
                                  <Icon name="exchange" size='large'/>
                                </span>
                                <span className="menu-text">Миний захиалгууд</span>
                              </Link>
                            </div>
                            <div className="dropdown-item padding" onClick={() => this.logout()}>
                              <Icon name="stop circle outline" size="large"/>
                              <span className="menu-text">Вебсайтаас гарах</span>
                            </div>
                          </div> : null
                        }
                      </div>
                    </React.Fragment>
                    :
                    <Link to="/login">
                      <button className="btn btn-trans-white btn-40">
                        <i className="icon icon-user"></i>&nbsp;
                        Нэвтрэх
                      </button>
                    </Link>
                    }
                  </div>
                  <div className={"basket" + (this.props?.basketValue > 0 ? " highlighted" : "")} onClick={() => this.openBasketSidebar()}>
                      <i className="icon icon-cart">
                        <span className="basket-count">{`${this.props?.basketCount}`}</span>
                      </i>&nbsp;
                      <span className="text">САГС</span>
                      <span className="basket-value">
                        <NumberFormat displayType={'text'} value={this.props.basketValue} suffix="₮" thousandSeparator={true}/>
                      </span>
                  </div>
                  </React.Fragment>
                  }
                </div>

              </div>
        </div>

        <div className="lower-container">
          <Container fluid={true} className="page">
            <Col xs={12} sm={12} md={12}>
              <div className="lower-section">
                <div className="logo section">
                  <Link to="/" className="logo-text">
                    <img src="/logo.jpg" alt="logo" className="logo-img" width="100"/>
                  </Link>
                </div>
                <div className="search section">
                  <SearchInputComponent></SearchInputComponent>
                </div>
              </div>
            </Col>
          </Container>
        </div>

        <div className="top-categories-container" id="top-categories-container">
          <TopCategories></TopCategories>
        </div>

        <div className="left-sidebar-container">
          {
            this.props.sidebarOpen ? <SidebarCategories></SidebarCategories> : null
          }
        </div>

        <div className="right-sidebar-container">
          {
            this.props.basketOpen ? <BasketSidebar></BasketSidebar> : null
          }
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(MainLayout);