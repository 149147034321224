import React, { Component } from 'react';
import './categories.scss';
import CategoryItem from './category-item';
import { Col } from 'react-grid-system';

interface CategoriesProp {
categories: any[]
}

class Categories extends Component<CategoriesProp> {

  render() {
    return (
      <div className={"categories-container" + (this.props.categories?.length > 0 ? " has-content" : "")}>
        {this.props.categories.map((category, i) => {
          return <Col xs={6} sm={4} md={4} lg={4} xl={3} key={category.id} className="category-wrap">
            <CategoryItem item={category}></CategoryItem>
          </Col>
        })}
      </div>
    );
  }
}

export default Categories