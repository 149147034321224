import React, { Component } from 'react';
import styled from 'styled-components';
import { Redirect, Link } from 'react-router-dom';
import { Table, Icon, Loader, Dimmer, Form, Pagination } from 'semantic-ui-react';
import "./products.scss";

const ProductCount = styled.span`
    margin-right: 20px;
    font-weight: 700;
`;

const loginUrl = "/provider-login"

interface ProviderState {
    errorMsg: string,
    redirect: string,
    products: any[],
    newCount: number,
    loading: boolean,
    page: any,
    searchValue: any,
}

interface ProviderProps {

}

class ProviderProductsComponent extends Component<ProviderProps, ProviderState> {
    constructor(props: any) {
        super(props)
        this.state = {
            errorMsg: "",
            redirect: "",
            products: [],
            newCount: 0,
            loading: true,
            page: { page: 1, size: 15, totalPages: 1, totalRows: 0},
            searchValue: ""
        }
        this.onSearchValueChange = this.onSearchValueChange.bind(this)
        this.onPageChange = this.onPageChange.bind(this)
    }

    componentDidMount() {
        // fetch products
        this.fetchWarehouse(1)
    }

    onPageChange(event: any, data: any) {
        this.setState({ page: Object.assign(this.state.page, { page: data.activePage }) })
        this.fetchWarehouse(data.activePage)
    }

    fetchWarehouse(pageNum: number) {
        var token = localStorage.getItem('providerToken')
        if (token && token !== 'undefined') {
            this.setState({ loading: true })
            fetch(process.env.REACT_APP_CHEF_API + 'provider/products', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({searchValue: this.state.searchValue, page: { page: pageNum, size: this.state.page.size }})
            })
            .then((res: any) => {
                if (res.ok) {
                    res.json().then((resJson: any) => {
                        let products = resJson.data;
                        products.forEach((p: any) => {
                            p.newCount = p.count
                            p.editingCount = false
                            p.editingMinCount = false
                        });
                        this.setState({ loading: false, products: products, page: resJson.page })
                    })
                } else {
                    this.setState({ redirect: loginUrl })
                }
            })
            .catch(err => {
                this.setState({ loading: false })
                console.error(err)
            })
        }
        else {
            this.setState({ redirect: loginUrl })
        }
    }

    onSearchValueChange(e: any, drop: any) {
        this.setState({ searchValue: drop.value })
    }

    clearSearch() {
        this.setState({ searchValue: " "}, () => {
            this.fetchWarehouse(1)
        })
        
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <React.Fragment>
                <Dimmer active={this.state.loading}>
                    <Loader size='medium'></Loader>
                </Dimmer>
                <section className="page margin-top-50">
                    <div className="width-100 text-center"><h2>Бүтээгдэхүүний жагсаалт</h2></div>

                    <div className="width-100 margin-top-20">
                        <Form>
                            <Form.Group>
                                <Form.Input width={16} placeholder="бүтээгдэхүүний нэр" type="text" value={this.state.searchValue || ""} onChange={this.onSearchValueChange}></Form.Input>
                            </Form.Group>
                            <Form.Group>
                                <Form.Button color="red" onClick={() => this.fetchWarehouse(1)}><Icon name="search"/>Хайх</Form.Button>
                                <Form.Button color="red" onClick={() => this.clearSearch()}><Icon name="remove circle"/></Form.Button>
                            </Form.Group>
                        </Form>
                    </div>

                    {/* <p>Нийт {this.state.page.totalRows} бүтээгдэхүүн олдлоо</p> */}

                    <Table width={16} className="products-table">
                        <Table.Header className="hidden-xs">
                            <Table.Row>
                                <Table.HeaderCell>Бүтээгдэхүүний нэр</Table.HeaderCell>
                                <Table.HeaderCell>Үлдэгдэл</Table.HeaderCell>
                                <Table.HeaderCell className="cursor" title={`Бүтээгдэхүүний үлдэгдэл хэмжээ нь тус хэмжээнээс бага болох үед танд имэйл илгээж мэдэгдэнэ`}>Анхааруулах хэмжээ
                                    <Icon name="info circle" color="red" title={`Бүтээгдэхүүний үлдэгдэл хэмжээ нь тус хэмжээнээс бага болох үед танд имэйл илгээж мэдэгдэнэ`}></Icon>
                                </Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell>Худалдаанд идэвхитэй</Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {this.state?.products?.map((product: any, index: number) => {
                                return <Table.Row key={product.id}>
                                    <Table.Cell>
                                        <Link className="underline" style={{"color": "black"}} to={"/provider/warehouse-actions/" + product.warehouseId}><strong>{product.name}</strong></Link>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <span className="show-xs">Үлдэгдэл: </span>
                                        <ProductCount>{product.count}</ProductCount>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <span className="show-xs">Үлдэгдэл: </span>
                                        <ProductCount title={`Тус бүтээгдэхүүний үлдэгдэл хэмжээ нь ${product.minCount}-с бага болох үед танд имэйл илгээж мэдэгдэнэ`}>{product.minCount}</ProductCount>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Link className="underline" style={{color: 'black'}} to={"/provider/warehouse/" + product.warehouseId}>Өөрчлөх&nbsp;<Icon name="pencil"/></Link>
                                    </Table.Cell>
                                    <Table.Cell> {product.active ? <Icon name="checkmark" /> : null} </Table.Cell>
                                    <Table.Cell>
                                        { product.active === 1 ? 
                                        <a target="_blank" style={{'color': '#484847'}} className="underline hover-blue" href={"/product/" + product.slug}>Линк</a>
                                        : null }
                                    </Table.Cell>
                                </Table.Row>
                            })
                            }
                        </Table.Body>
                    </Table>

                    <div className="flex-div margin-top-50">
                        <Pagination defaultActivePage={1} value={this.state.page.page} totalPages={this.state.page.totalPages} onPageChange={this.onPageChange}></Pagination>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default ProviderProductsComponent;