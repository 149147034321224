import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { Link, useHistory } from 'react-router-dom';
import { Button, Dimmer, Form, Icon, Loader, Table } from 'semantic-ui-react';
import OrderModel from './order.model';
import { parseStatus, formatDateTime, formatMongolianName } from '../../../utils/shared.utils';
import './order.scss';

function OrderComponent(props: any) {

  const history = useHistory()
  const [order, setOrder] = useState(new OrderModel())
  const [confirming, setConfirming] = useState(false)
  const [cancelling, setCancelling] = useState(false)
  const [loading, setLoading] = useState(true)
  const [errorMsg, setErrorMsg] = useState("")
  const [fetchedOrder, setFetchedOrder] = useState(false)

  // payment
  const [amount, setAmount] = useState(0)
  const [txnId, setTxnId] = useState("")

  useEffect(() => {
    if (props?.match?.params?.orderId !== undefined && props?.match?.params?.orderId !== null) {
      getOrder();
      window.scrollTo(0, 0)
    }
  }, [props.match?.params])

  const getOrder = () => {
    if (fetchedOrder) {
      return
    }
    var token = localStorage.getItem('adminToken')
    if (token && token !== 'undefined') {
      setFetchedOrder(true)
      fetch(process.env.REACT_APP_CHEF_API + 'mongol/order/' + props.match.params.orderId, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': token,
          'Content-Type': 'application/json'
        }
      }).then((res: any) => {
        setLoading(false)
        if (res.ok) {
          res.json().then((resJson: any) => {
            let orderObj = resJson.data;
            for (let delivery of orderObj.deliveries) {
              let date = new Date(delivery.date)
              delivery.date = `${date.getMonth() + 1} сарын ${date.getUTCDate()}`
              // for (let item of delivery.items) {
              // item.product.imageXsUrl = `https://${bucket}.s3.${region}.amazonaws.com/${item.product.imageXsUrl}`
              // }
            }
            setOrder(orderObj)
          })
        }
      }).catch(err => {
        setLoading(false)
        setErrorMsg("Сүлжээний алдаа")
        console.error(err)
      })
    }
    else {
      window.alert("Та дахин нэвтэрнэ үү")
      history.push("/mongol/login")
    }
  }

  const submitConfirm = () => {
    setConfirming(false)
    setLoading(true)
    var token = localStorage.getItem('adminToken')
    if (token && token !== 'undefined') {
      fetch(process.env.REACT_APP_CHEF_API + 'mongol/order/confirm', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ orderId: order.id })
      }).then((res: any) => {
        setLoading(false)
        if (res.ok) {
          setOrder(Object.assign({}, order, { status: 'CONFIRMED' }))
        }
        else if (res.status === 409) {
          setErrorMsg("Тус захиалга цуцлагдсан байна. Барааны үлдэгдэл хүрэлцэхгүй учраас захиалгыг дахин үүсгэх чадсангүй.")
        }
        else {
          setErrorMsg("Серверт алдаа гарлаа")
        }
      }).catch(err => {
        setLoading(false)
        setErrorMsg("Сүлжээний алдаа")
        console.error(err)
      })
    }
    else {
      window.alert("Та дахин нэвтэрнэ үү")
      history.push("/mongol/login")
    }
  }

  const cancelOrder = () => {
    setCancelling(false)
    setLoading(true)
    var token = localStorage.getItem('adminToken')
    if (token && token !== 'undefined') {
      fetch(process.env.REACT_APP_CHEF_API + 'mongol/order/cancel/' + order.id, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        body: ""
      }).then((res: any) => {
        setLoading(false)
        if (res.ok) {
          setOrder(Object.assign({}, order, { status: 'ARCHIVED' }))
        }
        else {
          setErrorMsg("Серверт алдаа гарлаа")
        }
      }).catch(err => {
        setLoading(false)
        setErrorMsg("Сүлжээний алдаа")
        console.error(err)
      })
    }
    else {
      window.alert("Та дахин нэвтэрнэ үү")
      history.push("/mongol/login")
    }
  }

  const submitPayment = () => {
    if (!txnId) {
      setErrorMsg("Гүйлгээний дугаар оруулна уу")
      return;
    }
    if (!amount) {
      setErrorMsg("Мөнгөн дүн оруулна уу")
      return;
    }
    setLoading(true)
    var token = localStorage.getItem('adminToken')
    if (token && token !== 'undefined') {
      fetch(process.env.REACT_APP_CHEF_API + 'mongol/order/payment', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ orderId: order.id, amount, txnId: txnId })
      }).then((res: any) => {
        setLoading(false)
        if (res.ok) {
          setAmount(0)
          setTxnId("")
          setErrorMsg("")
          res.json().then((resJson: any) => {
            order.payments.push(resJson)
            setOrder(Object.assign({}, order))
          })
        }
        else {
          setErrorMsg("Төлбөр бүртгэхэд алдаа гарлаа")
        }
      }).catch(err => {
        setLoading(false)
        setErrorMsg("Сүлжээний алдаа")
        console.error(err)
      })
    }
    else {
      window.alert("Та дахин нэвтэрнэ үү")
      history.push("/mongol/login")
    }
  }

  const toggleDelete = (index: number) => {
    order.payments[index].confirmingDelete = !order.payments[index].confirmingDelete ? true : false;
    setOrder(Object.assign({}, order))
  }

  const deletePayment = (index: number) => {
    if (!order.payments[index]) {
      return;
    }
    var token = localStorage.getItem('adminToken')
    if (token && token !== 'undefined') {
      let toDelete = order.payments[index];
      fetch(`${process.env.REACT_APP_CHEF_API}mongol/order/payment/${toDelete.id}`, {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Authorization': token,
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          if (res.ok) {
            order.payments.splice(index, 1)
            setOrder(Object.assign({}, order))
          }
          else {
            setErrorMsg("Төлбөр устгахад алдаа гарлаа")
          }
        });
    }
  }

  return (
    <div className="page page-order">
      <div className="payment-section width-100">
        <div className="order-payments">
          <h2>Төлбөр</h2>

          <div>
            <p>
              Хэрэглэгч: <Link className="underline cursor" to={"/mongol/client/" + order.clientId}>{formatMongolianName(order.parentName, order.ownName)}</Link>
            </p>
            {order.companyName ? <p>
              Байгууллага: <Link className="underline cursor" to={"/mongol/company/" + order.companyId}>{order.companyName}</Link>
            </p> : null}
            <br />
          </div>

          <Table width={16}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Төлөв</Table.HeaderCell>
                <Table.HeaderCell>Гүйлгээний дугаар</Table.HeaderCell>
                <Table.HeaderCell>Мөнгөн дүн</Table.HeaderCell>
                <Table.HeaderCell>Бүртгэсэн</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {
                order?.payments.map((payment: any, pindex: number) => {
                  return <Table.Row className="basket-item" key={"basket-item-" + pindex}>
                    <Table.Cell>{payment.status}</Table.Cell>
                    <Table.Cell>{payment.txnId}</Table.Cell>
                    <Table.Cell>
                      <NumberFormat displayType="text" value={payment.amount} suffix="₮" thousandSeparator={true} />
                    </Table.Cell>
                    <Table.Cell>{payment.method} ({payment.createdBy})</Table.Cell>
                    <Table.Cell>{payment?.createdAt ? formatDateTime(new Date(payment?.createdAt)) : null}</Table.Cell>
                    <Table.Cell>
                      {payment.confirmingDelete ? <React.Fragment>
                        <Button primary size='mini' onClick={() => deletePayment(pindex)}>Устга</Button>
                        <Button size='mini' onClick={() => toggleDelete(pindex)}>Болих</Button>
                      </React.Fragment> : <React.Fragment>
                        {payment.method === 'Admin' ?
                          <Icon name="trash" className="cursor chef-icon-hover padding-5" onClick={() => toggleDelete(pindex)} /> : null
                         }
                      </React.Fragment>
                      }
                    </Table.Cell>
                  </Table.Row>
                })
              }
            </Table.Body>
          </Table>
        </div>

        {errorMsg ? <div className="text-center error-msg width-100">{errorMsg}</div> : null}

        <div className="payment-form">
          <Form>
            <Form.Input type="text" label="Төлбөр бүртгэх" placeholder="Гүйлгээний дугаар" value={txnId} onChange={(e) => setTxnId(e.target.value)} />
            <Form.Input type="number" label="Мөнгөн дүн" value={amount} onChange={(e) => setAmount(Number(e.target.value))} />
            <Form.Button color="olive" onClick={() => submitPayment()}>Төлбөр бүртгэх</Form.Button>
          </Form>

          {order.status === 'PLACED' || order.status === 'ARCHIVED' ? <div className="order-confirm">
            <div className="text-center width-100">{confirming ? <p>Захиалга баталгаажуулах?</p> : null}</div>
            <div className="confirm-order flex-div">
              {confirming ? <React.Fragment>
                <Button color="green" onClick={() => submitConfirm()}>Баталгаажуулах</Button>
                <Button color="red" onClick={() => setConfirming(false)}>Буцах</Button>
              </React.Fragment> : <Button color="orange" onClick={() => setConfirming(true)}>Захиалга Баталгаажуулах</Button>}
            </div>
          </div> : null}

          {order.status === 'CONFIRMED' ? <div className="order-cancel">
            <div className="text-center width-100">{confirming ? <p>Захиалга цуцлах?</p> : null}</div>
            <div className="cancel-order flex-div">
              { cancelling ? <React.Fragment>
                <Button color="red" onClick={() => cancelOrder()}>Цуцлах</Button>
                <Button color="yellow" onClick={() => setCancelling(false)}>Буцах</Button>
              </React.Fragment> : <Button color="red" onClick={() => setCancelling(true)}>Захиалга цуцлах</Button>}
            </div>
          </div> : null}
        </div>
      </div>

      { order.status === "CONFIRMED" ? <React.Fragment>
      <div className="print-line">
        <span className="cursor" onClick={() => window.print()}>
          <Icon className="cursor" name="print" size="big" />&nbsp;Баримт хэвлэх</span>
      </div>

      <div className="receipt-container">
        <Dimmer active={loading} inverted>
          <Loader size='medium'></Loader>
        </Dimmer>
        {[1, 2].map((dummy: any, dummyi: number) => {
        return <div className="receipt" key={"receipt-"+dummyi}>
          <h2>Баримт</h2>
          <div>
            <div className="client-order">
              <div className="order-top">
                <div className="order-col order-id fontMain-2">
                  <div className="col-top">№</div>
                  <div>
                    <strong>{order?.token}</strong>
                  </div>
                </div>
                <div className="order-col order-amount">
                  <div className="col-top">Нийт дүн</div>
                  <div>
                    <NumberFormat displayType={'text'} value={order?.totalAmount} suffix="₮" thousandSeparator={true} />
                  </div>
                </div>
                <div className="order-col">
                  <div className="col-top">Төлөв</div>
                  <div className={`order-status ${String(order.status || '').toLowerCase()}`}>{parseStatus(order.status)}</div>
                </div>
              </div>

              <div className="order-items">
                <div className="order-contact">
                  <strong>Утас: </strong>
                  {order.phone}
                </div>
                <div className="order-address">
                  <strong className="col-top">Хаяг: </strong>
                  <span>{order.town}, {order.district}, {order.unit}, {order.street}</span>
                </div>

                {order.deliveries?.map((delivery: any, dindex: number) => {
                  return <div className="order-delivery" key={'delivery-' + delivery.id}>
                    <div className="order-delivery-top">
                      <span className="delivery-date">
                        <Icon name="calendar outline" />{delivery?.date} &nbsp;
                      </span>
                      <span className="delivery-cost"> - Хүргэлтийн үнэ: - <NumberFormat displayType="text" value={delivery?.cost} thousandSeparator={true} suffix="₮" /></span>
                    </div>
                    <table>
                      <tbody>
                        {delivery?.items?.map((order: any, index: number) => {
                          return <tr key={`order-${order.id}-${index}`}>
                            <td>{order.product.name}</td>
                            <td>
                              <NumberFormat displayType="text" value={order.price} suffix="₮" thousandSeparator={true} /> x {order.count}
                              &nbsp;=&nbsp;
                              <NumberFormat displayType="text" value={order.price * order.count} suffix="₮" thousandSeparator={true} />
                            </td>
                          </tr>
                        })}
                      </tbody>
                    </table>
                  </div>
                })
                }
              </div>
              <div className="order-summary">
                <div>Барааны нийт үнэ: <NumberFormat displayType="text" value={order.totalAmount - order.totalDeliveryCost} suffix="₮" thousandSeparator={true} /></div>
                <div>Хүргэлтийн нийт үнэ: <NumberFormat displayType="text" value={order.totalDeliveryCost} suffix="₮" thousandSeparator={true} /></div>
                <div>Нийт үнэ: <NumberFormat displayType="text" value={order.totalAmount} suffix="₮" thousandSeparator={true} /></div>
                <br />
                <hr />
                <div>Хүргэсэн: </div>
                <br />
                <div>Хүлээн авсан: </div>
              </div>
            </div>
          </div>
        </div>
        })}
      </div>
    </React.Fragment> : null }
    </div>
  );
}

export default OrderComponent;