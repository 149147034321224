import React, { useEffect, useState } from 'react';
import './delivery.scss';
import store from '../../store';
import { connect } from 'react-redux';
import { Button, Select, Icon, Dimmer, Loader } from 'semantic-ui-react';
import { ClientModel } from '../../entities/client.model';
import { useHistory } from 'react-router-dom';
import CheckoutHeader from './checkout-header';
import NumberFormat from 'react-number-format';
import AddressComponent from '../address/address.component';
import { AddressModel } from '../../entities/address.model';
import { formatDate } from '../../utils/shared.utils';

interface DeliveryProps {
  basketItems: any,
  client: ClientModel,
  settings: any,
  basketAddress: AddressModel,
  basketAddressValue: string,
  basketWarehouseUpdates: string,
}

const mapStateToProps = (state: any) => {
  let basketItems: any[] = [];
  let totalAmount: number = 0;
  let productMap = new Map<Number, any>();
  let basketWarehouseUpdates = "";

  if (state.products && state.basket && state.basket.quantityById) {
    state.products.forEach((p: any) => {
      let product = Object.assign({}, p)
      if (state.basket.quantityById[product.id] !== null && state.basket.quantityById[product.id] !== undefined) {
        // exists in the basket
        product['count'] = state.basket.quantityById[product.id];
        // push to list
        basketItems.push(product);
        totalAmount += product.count * (product.saleActive ? product.salePrice : product.price);
        productMap.set(product.id, product);
        basketWarehouseUpdates += `${product.id}=${product.warehouseCount}, `
      }
    });
  }

  return {
    basketItems: basketItems,
    totalAmount: totalAmount,
    client: state.client,
    settings: state.settings,
    deliveryAddress: state.basket.address,
    basketAddress: state.basket.address,
    basketAddressValue: state.basket.addressValue,
    basketWarehouseUpdates: basketWarehouseUpdates,
  }
}

function DeliveryPage(props: DeliveryProps) {

  let history = useHistory()
  let scrolled = false;

  const deliveryDayMargins = [1, 3, 4];

  const [headerIndex, setHeaderIndex] = useState(1)
  const [errorMsg, setErrorMsg] = useState("")
  const [addressError, setAddressError] = useState("")
  const [submitted, setSubmitted] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const [addressOptions, setAddressOptions] = React.useState<any[]>([])
  const [selectedAddressValue, setSelectedAddressValue] = React.useState<any>('')
  const [orderAddress, setOrderAddress] = React.useState<any>(new AddressModel())
  const [newAddress, setNewAddress] = React.useState(new AddressModel())
  const [saveAddress, setSaveAddress] = React.useState(false)

  const [totalAmount, setTotalAmount] = React.useState(0)
  const [deliveryDateMap, setDeliveryDatesMap] = React.useState<Map<Number, any>>(new Map())// {day margin group: selected day margin}
  const [deliveryDateOptionsMap, setDeliveryDateOptionsMap] = React.useState<Map<Number, any>>(new Map())
  const [deliveryItemsMaster, setDeliveryItemsMaster] = React.useState<any[]>([])
  const [showCustomPriceMap, setShowCustomPriceMap] = React.useState(new Map())
  const [customPriceMap, setCustomPriceMap] = React.useState(new Map())
  const [addressMap, setAddressMap] = React.useState(new Map())
  const [showPayLater, setShowPayLater] = React.useState(false)

  useEffect(() => {
    setTimeout(() => {
      if (!scrolled) {
        scrolled = true;
        window.scrollTo(0, 0);
      }
    });
  }, [])

  useEffect(() => {
    setTimeout(() => {
      prepareItems()
    });
  }, [props.basketItems, deliveryDateMap, customPriceMap])

  useEffect(() => {
    setTimeout(() => {
      prepareDeliveryOptions()
    });
  }, [props.settings, deliveryItemsMaster])

  async function fetchClients() {
    var token = localStorage.getItem('clientToken')
    if (token && token !== 'undefined') {
      fetch(`${process.env.REACT_APP_CHEF_API}client-list`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': token,
          'Content-Type': 'application/json'
        }
      }).then(res => {
        if (res.ok) {
          res.json().then((resJson: any) => {
            setErrorMsg("")
            let options = []
            let addressMap = new Map()
            for (let i = 0; i < resJson.length; i++) {
              let company = resJson[i]
              // let clientAddress = `${client.town}, ${client.district}, ${client.unit}, ${client.street}`
              // if (client.town && client.district && client.unit && client.street) {
              //   options.push({
              //     key: 'client-' + client.clientId,
              //     value: 'client-' + client.clientId,
              //     text: client.company.name + ", Хаяг: " + clientAddress
              //   })
              // }
              if (company.town && company.district && company.unit && company.street) {
                let companyAddress = `${company.town}, ${company.district}, ${company.unit}, ${company.street}`
                // if (clientAddress !== companyAddress) {
                options.push({
                  key: company.id,
                  value: company.id,
                  text: company.name + ", Хаяг: " + companyAddress
                })
                // }
                addressMap.set(company.id, {
                  canPayLater: company.canPayLater,
                  address: AddressModel.createAddressModel(company)
                })
              }
            }
            setAddressOptions(options)
            setAddressMap(addressMap)
          })
        }
      }).catch(err => {
        setErrorMsg("Сүлжээний алдаа")
        console.error(err);
      })
    } else {
      console.log("Will redirect to: " + window.location.pathname)
      store.dispatch({ type: "redirectUrl", data: window.location.pathname })
      history.push("/login")
    }
  }

  useEffect(() => {
    if (props.client.superUser === 1) {
      // fetch client list
      fetchClients();
    } else {
      let options = []
      // client address
      if (props?.client?.street && props?.client?.unit && props?.client?.district) {
        options.push({
          key: 0,
          value: 'clientAddress',
          text: `${props.client.town}, ${props.client.district}, ${props.client.unit}, ${props.client.street}`
        })
        // select client address by default
        if (!props.basketAddressValue || props.basketAddressValue === 'clientAddress') {
          setOrderAddress(AddressModel.createAddressModel(props.client))
          setSelectedAddressValue('clientAddress')
        }
      }
      // company address
      if (props.client?.company && props.client?.company?.id && props.client?.company?.street && props.client?.company?.unit && props.client?.company?.district) {
        if ((options.length === 0 && !props.basketAddressValue) || props.basketAddressValue === 'companyAddress') {
          // select company address by default
          setOrderAddress(AddressModel.createAddressModel(props.client.company))
          setSelectedAddressValue('companyAddress')
        }
        options.push({
          key: 1,
          value: 'companyAddress',
          text: `${props.client.company.town}, ${props.client.company.district}, ${props.client.company.unit}, ${props.client.company.street}`,
        })
      }
      setAddressOptions(options)

      // new address required
      if (options.length === 0) {
        setSelectedAddressValue('newAddress')
      }

      setShowPayLater(props?.client?.company?.canPayLater)
    }
  }, [props.client])

  const prepareItems = () => {
    if (props?.basketItems?.length > 0) {
      let deliveryItems: any[] = [];
      let marginPosMap = new Map();

      for (let dayMargin of deliveryDayMargins) {
        deliveryItems.push({ dayMargin: dayMargin, products: [], totalAmount: 0, deliveryCost: 0 })
        marginPosMap.set(dayMargin, deliveryItems.length - 1)
      }
      // find day margin group for each product
      for (let basketItem of props.basketItems) {
        if (customPriceMap.has(basketItem.id)) {
          if (customPriceMap.get(basketItem.id) > 0) {
            // apply custom price
            basketItem.saleActive = false;
            basketItem.price = customPriceMap.get(basketItem.id);
          }
        } else {
          let price = basketItem.saleActive ? basketItem.salePrice : basketItem.price
          customPriceMap.set(basketItem.id, price)
        }
        // find delivery dates
        for (let ii = 0; ii < deliveryDayMargins.length; ii++) {
          let dMargin = deliveryDayMargins[ii]
          if (basketItem.deliveryDayMargin <= dMargin) {
            // found group
            let postInMap = marginPosMap.get(dMargin)
            deliveryItems[postInMap].products.push(basketItem);
            deliveryItems[postInMap].totalAmount += basketItem.count * (basketItem.saleActive ? basketItem.salePrice : basketItem.price)
            break;
          }
          else if (dMargin < deliveryDayMargins[deliveryDayMargins.length - 1]) {
            // pass item to the next group
            continue;
          }
          else {
            // item falls to the last group (far delivery)
            let postInMap = marginPosMap.get(dMargin)
            if (basketItem.deliveryDayMargin > dMargin) {
              // lengthen the delivery day because of the current item delivery margin
              deliveryItems[postInMap].dayMargin = basketItem.deliveryDayMargin
            }
            deliveryItems[postInMap].products.push(basketItem)
            deliveryItems[postInMap].totalAmount += basketItem.count * (basketItem.saleActive ? basketItem.salePrice : basketItem.price)
            marginPosMap.set(basketItem.deliveryDayMargin, postInMap)
            break;
          }
        }
      }

      // group day margins per selected delivery date
      let deliveryItemsMaster: any[] = [{ date: null, groups: [] }];
      let datePosMap = new Map();
      let total = 0;
      datePosMap.set(null, 0)

      for (let deliveryObj of deliveryItems) {
        if (deliveryObj.products.length > 0) {
          total += deliveryObj.totalAmount;
          if (deliveryDateMap.has(deliveryObj.dayMargin)) {
            let date = deliveryDateMap.get(deliveryObj.dayMargin)
            if (datePosMap.has(date)) {
              deliveryItemsMaster[datePosMap.get(date)].groups.push(deliveryObj)
              deliveryItemsMaster[datePosMap.get(date)].totalAmount += deliveryObj.totalAmount
            }
            else {
              deliveryItemsMaster.push({ date: date, groups: [deliveryObj], totalAmount: deliveryObj.totalAmount, deliveryCost: 0 })
              datePosMap.set(date, deliveryItemsMaster.length - 1)
            }
            setHeaderIndex(2)
          }
          else {
            deliveryItemsMaster[0].groups.push(deliveryObj)
          }
        }
      }

      for (let delGroup of deliveryItemsMaster) {
        if (delGroup.date && delGroup.groups?.length > 0) {
          delGroup.deliveryCost = delGroup.totalAmount >= props.settings.freeDeliveryMinAmount ? 0 : props.settings.deliveryPrice
          total += delGroup.deliveryCost
        }
        else {
          delGroup.deliveryCost = 0;
        }
      }

      setDeliveryItemsMaster(deliveryItemsMaster)
      setTotalAmount(total)
    }
  }

  const prepareDeliveryOptions = () => {
    if (deliveryItemsMaster?.length === 0 || props.basketItems?.length === 0) {
      return;
    }
    if (!props.settings.deliveryMaxDays) {
      return;
    }

    let deliveryDateOptionsMap = new Map()

    // populate delivery date options
    let dayOffSet = new Set();
    props.settings.dayOffs.forEach((item: any) => dayOffSet.add(formatDate(new Date(item.date))))
    for (let delGroup of deliveryItemsMaster) {
      for (let deliveryObj of delGroup.groups) {
        if (deliveryObj.products.length > 0) {
          deliveryDateOptionsMap.set(deliveryObj.dayMargin, { dateOptions: [] })
          let deliveryDate = new Date();
          deliveryDate.setDate(deliveryDate.getDate() + deliveryObj.dayMargin);
          let dateOptions = []
          for (let ii = 0; ii < props.settings.deliveryMaxDays; ii++) {
            deliveryDate.setDate(deliveryDate.getDate() + 1);
            if (dayOffSet.has(formatDate(deliveryDate))) {
              continue;
            }
            let dateOption = {
              key: `margin-${deliveryObj.dayMargin}-${deliveryObj.dayMargin + ii}`,
              value: deliveryObj.dayMargin + ii,
              text: `${deliveryDate.getMonth() + 1} сарын ${deliveryDate.getUTCDate()}`,
            };
            dateOptions.push(dateOption)
          }
          deliveryDateOptionsMap.get(deliveryObj.dayMargin).dateOptions = dateOptions;
        }
      }
    }
    setDeliveryDateOptionsMap(deliveryDateOptionsMap)
  }

  const continueAction = (paylater: boolean) => {
    if (props?.client?.id) {
      // validate delivery dates
      setSubmitted(true)
      for (let delGroup of deliveryItemsMaster) {
        for (let deliveryObj of delGroup.groups) {
          if (!deliveryDateMap.has(deliveryObj.dayMargin)) {
            setErrorMsg(`Хүргэх өдрийг сонгоно уу: ${deliveryObj.products.map((p: any) => p.name).join(", ")}`)
            return;
          }
        }
      }
      // validate address
      if (!orderAddress.town || !orderAddress.district || !orderAddress.unit || !orderAddress.street) {
        setAddressError("Та хүргэх хаяг оруулна уу");
        return;
      }
      // submit order
      submitOrder(paylater);
    }
  }

  function preparePayload(paylater: boolean) {
    let items: any[] = [];

    for (let delGroup of deliveryItemsMaster) {
      for (let deliveryObj of delGroup.groups) {
        for (let basketItem of deliveryObj.products) {
          if (basketItem.count) {
            let item = {
              productId: basketItem.id,
              count: basketItem.count,
              deliveryDay: delGroup.date,
              saveAddress: saveAddress,
              customPrice: 0
            }
            if (props.client.superUser === 1 && customPriceMap.has(basketItem.id)) {
              item.customPrice = customPriceMap.get(basketItem.id)
            }
            items.push(item)
          }
        }
      }
    }

    return {
      payLater: paylater,
      companyId: props.client?.superUser === 1 ? selectedAddressValue : props.client?.company?.id,
      products: items,
      address: orderAddress
    };
  }

  const submitOrder = (paylater: boolean) => {
    var token = localStorage.getItem('clientToken')
    if (token && token !== 'undefined') {
      setErrorMsg("")
      setAddressError("")
      let payload = preparePayload(paylater);
      console.log("payload", payload)
      setSubmitting(true)
      fetch(`${process.env.REACT_APP_CHEF_API}client/order-new`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      }).then(res => {
        setSubmitting(false)
        if (res.ok) {
          res.json().then((resJson: any) => {
            setErrorMsg("")
            setAddressError("")
            store.dispatch({
              type: 'clearBasket'
            })
            store.dispatch({ type: 'REFRESH_PRODUCTS' })
            if (resJson.status === 'PLACED') {
              history.push("/client/payment/" + resJson.token)
            }
            else if (resJson.status === 'CONFIRMED') {
              history.push("/client/order/success/" + resJson.token)
            }
          })
        }
        else if (res.status === 409) {
          // not enough stock
          res.json().then((resJson: any) => {
            if (resJson.errors) {
              let errorMsg = "Үлдэгдэл хүрэлцэхгүй бүтээгдэхүүн: "
              for (let stockErr of resJson.errors) {
                errorMsg += `${stockErr.name}, `
              }
              errorMsg = errorMsg.substring(0, errorMsg.length - 2)
              setErrorMsg(errorMsg)
            }
          })
        }
        else {
          res.json().then((resJson: any) => {
            if (resJson && resJson.error) {
              setErrorMsg(resJson.error);
            }
            else {
              setErrorMsg("Серверт алдаа гарлаа")
            }
          })
        }
      }).catch(err => {
        setSubmitting(false)
        setErrorMsg("Сүлжээний алдаа")
        console.error(err);
      })
    }
    else {
      console.log("Will redirect to: " + window.location.pathname)
      store.dispatch({ type: "redirectUrl", data: window.location.pathname })
      history.push("/login")
    }
  }

  const onHeaderClick = (index: any) => {
  }

  const onCustomPriceChange = (product: any, e: any) => {
    if (!e.target.value || e.target.value === "0") {
      return;
    }
    let customPrice: number = Number(e.target.value);
    let tempMap = new Map(customPriceMap);
    tempMap.set(product.id, customPrice);
    setCustomPriceMap(tempMap);
  }

  const toggleCustomPrice = (product: any) => {
    let tempMap = new Map(showCustomPriceMap);
    if (showCustomPriceMap.has(product.id)) {
      tempMap.delete(product.id)
    } else {
      tempMap.set(product.id, true)
    }
    setShowCustomPriceMap(tempMap);
  }

  const onItemCountChange = (product: any, e: any) => {
    if (!e.target.value || e.target.value === "0") {
      store.dispatch({
        type: 'updateBasketItemCount',
        product: product,
        count: "0"
      })
      return;
    }
    let count: number = Number(e.target.value);
    if (count > product.warehouseCount) {
      return;
    }
    store.dispatch({
      type: 'updateBasketItemCount',
      product: product,
      count: count
    })
  }

  const itemIncreaseCount = (product: any) => {
    let count = product.count;
    if (count >= product.warehouseCount) {
      return;
    }
    count += 1;
    store.dispatch({
      type: 'updateBasketItemCount',
      product: product,
      count: count
    })
  }

  const itemDecreaseCount = (product: any) => {
    let count = product.count;
    if (count < 1) {
      count = 0;
    }
    else {
      count -= 1;
    }
    store.dispatch({
      type: 'updateBasketItemCount',
      product: product,
      count: count
    })
  }

  const onItemCountBlur = (product: any, e: any) => {
    if (!e.target.value || e.target?.value === "0") {
      store.dispatch({
        type: 'removeItemFromBasket',
        product: product
      })
    }
  }

  const removeItemFromBasket = (product: any) => {
    store.dispatch({
      type: 'removeItemFromBasket',
      product: product
    });
  }

  const onDeliveryDateChange = (dayMargin: number, data: any) => {
    if (dayMargin === undefined || dayMargin === null) {
      return;
    }
    let deliveryDates = new Map(deliveryDateMap)
    deliveryDates.set(dayMargin, data.value);
    setDeliveryDatesMap(deliveryDates)
  }

  const onAddressChange = (e: any, data: any) => {
    setSelectedAddressValue(data.value)
    for (let addr of addressOptions) {
      if (addr.value === data.value) {
        let address;
        if (props.client.superUser === 1) {
          address = AddressModel.createAddressModel(addressMap.get(data.value).address)
          setShowPayLater(addressMap.get(data.value).canPayLater)
        } else {
          address = AddressModel.createAddressModel((data.value === 'clientAddress') ? props.client : props.client.company)
          setShowPayLater(props?.client?.company?.canPayLater)
          store.dispatch({
            type: 'basketAddress',
            data: address,
            value: data.value
          })
        }
        setOrderAddress(address)
        break;
      }
    }
  }

  const onNewAddressChange = (address: AddressModel) => {
    setNewAddress(address)
    if (address.town && address.district && address.unit && address.street) {
      setOrderAddress(address)
      store.dispatch({
        type: 'basketAddress',
        data: address,
        value: 'newAddress'
      })
      setAddressError("")
    }
  }

  const onAddressSaveChange = (e: any) => {
    setSaveAddress(e.target.checked)
  }

  return (
    <div className="page checkout-page">
      <Dimmer active={submitting}>
        <Loader size='medium'></Loader>
      </Dimmer>
      <div className="checkout-container">
        <div className="checkout-content">
          <CheckoutHeader activeIndex={headerIndex} onClick={onHeaderClick}></CheckoutHeader>

          <div className="delivery-list">
            {deliveryItemsMaster.filter(d => d?.groups?.length > 0).map((delGroup: any, qindex: number) => {
              return <div className="products-table" key={"delivery-date-" + delGroup.date}>
                {delGroup.groups.map((deliveryObj: any, dindex: number) => {
                  return <div className="products-group" key={"day-margin-" + deliveryObj.dayMargin}>
                    {delGroup.date ? null : <div className={submitted && (deliveryDateMap.get(deliveryObj.dayMargin) === undefined || deliveryDateMap.get(deliveryObj.dayMargin) === null) ? "error-msg" : "warning-msg"}>
                      <Icon name="info circle" />Хүргэх өдөр сонгоно уу</div>}

                    <div className="products-row delivery-item">
                      <div className="ritem date-picker">
                        <Icon name="calendar outline" size="large" color={submitted && (deliveryDateMap.get(deliveryObj.dayMargin) === undefined || deliveryDateMap.get(deliveryObj.dayMargin) === null) ? "red" : undefined} />
                        <span className="date-container">
                          <Loader active={deliveryDateOptionsMap.get(deliveryObj.dayMargin)?.dateOptions?.length > 0 ? false : true} size="small" />
                          <Select placeholder={deliveryDateOptionsMap.get(deliveryObj.dayMargin)?.dateOptions?.length > 0 ? 'Хүргэх өдөр' : ''}
                            error={submitted && (deliveryDateMap.get(deliveryObj.dayMargin) === undefined || deliveryDateMap.get(deliveryObj.dayMargin) === null)}
                            options={deliveryDateOptionsMap?.get(deliveryObj.dayMargin)?.dateOptions || []}
                            value={delGroup.date}
                            disabled={deliveryDateOptionsMap?.get(deliveryObj.dayMargin)?.dateOptions?.length > 0 ? false : true}
                            onChange={(e: any, data: any) => onDeliveryDateChange(deliveryObj.dayMargin, data)}></Select>
                        </span>
                        <div className="delivery-time">
                          <Icon name="clock outline" />
                          {props?.settings?.deliveryTime}
                        </div>
                      </div>
                    </div>

                    {deliveryObj.products.map((product: any, pindex: number) => {
                      return <div className="products-row basket-item" key={"basket-item-" + pindex}>
                        <div className="ritem product-image" onClick={() => window.open("/product/" + product.slug)}>
                          <img width="40" height="40" src={product.imageXsUrl} />
                        </div>
                        <div className="ritem product-row-right flex-between">
                          <div className="ritem product-details ">
                            <div className={"product-name" + (product.warehouseCount < product.count ? " count-error" : "")}>
                              <div className="name" onClick={() => window.open("/product/" + product.slug)}>{product.name}</div>
                              <div className="price-per">
                                <span>{product.size && product.unit ? (product.size + " " + product.unit) : ""}</span>
                                <span className="remove" onClick={() => removeItemFromBasket(product)}>
                                  <Icon name="trash" />устгах
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="ritem product-counts">
                            <div className="product-count">
                              <Icon name="caret down" onClick={() => itemDecreaseCount(product)} className={product.warehouseCount < product.count ? "count-error" : ""}></Icon>
                              <input type="number" value={product.count} min="0" className={product.warehouseCount < product.count ? "count-error" : ""}
                                onChange={(e) => onItemCountChange(product, e)}
                                onBlur={(e) => onItemCountBlur(product, e)} />
                              <Icon name="caret up" onClick={() => itemIncreaseCount(product)} className={product.warehouseCount < product.count ? "count-error" : ""}></Icon>
                            </div>
                            <div className="stock-line">{product.warehouseCount <= product.count ? ("Боломжит үлдэгдэл: " + product.warehouseCount) : ""}</div>
                          </div>
                          <div className="ritem product-price flex-between">
                            {showCustomPriceMap.has(product.id) ?
                              <input type="number" value={customPriceMap.get(product.id)} className=""
                                onChange={(e) => onCustomPriceChange(product, e)}
                              />
                              :
                              <React.Fragment>
                                <NumberFormat displayType={'text'} value={product.saleActive ? product.salePrice : product.price} suffix="₮" thousandSeparator={true} />
                              </React.Fragment>
                            }
                            <span>&nbsp;x</span>
                            <span>&nbsp;{product.count}</span>
                            <span>&nbsp;=&nbsp;</span>
                            <NumberFormat displayType={'text'} value={(product.saleActive ? product.salePrice : product.price) * product.count} suffix="₮" thousandSeparator={true} />
                            {props?.client?.superUser === 1 ?
                              <Button className='btn-price-change' onClick={() => toggleCustomPrice(product)}>
                                {showCustomPriceMap.has(product.id) ? "X" : "Үнэ өөрчлөх"}
                              </Button> : null
                            }
                          </div>
                        </div>
                      </div>
                    })
                    }
                  </div>
                })}

                {delGroup.totalAmount > 0 ?
                  <div className="products-row delivery-total-amount strong-text">
                    <div className="ritem flex-14 text-right">Бүтээгдэхүүний үнэ:</div>
                    <div className="ritem text-right">
                      <NumberFormat displayType={'text'} value={delGroup.totalAmount} suffix="₮" thousandSeparator={true} />
                    </div>
                  </div>
                  : null
                }

                {delGroup.date ? <div className="products-row delivery-total-amount strong-text">
                  <div className="ritem flex-14 text-right">Хүргэлтийн үнэ:</div>
                  <div className="ritem text-right">
                    <NumberFormat displayType={'text'} value={delGroup?.deliveryCost} suffix="₮" thousandSeparator={true} />
                  </div>
                </div> : null}
              </div>
            })}
          </div>

          <div className="delivery-info">
            <Icon name="info circle" />
            Нэг өдөр хүргэх нийт барааны үнэ <NumberFormat displayType={'text'} value={props.settings.freeDeliveryMinAmount} suffix="₮" thousandSeparator={true} />-с дээш бол үнэгүй хүргэнэ.
          </div>

          <div className="total-amount">
            <span>Нийт: <NumberFormat displayType={'text'} value={totalAmount} suffix="₮" thousandSeparator={true} /></span>
          </div>
        </div>

        <hr />

        <div className="address-section">
          <div className="address-section-inner">
            <h2>{props.client.superUser === 1 ? "Захиалагч байгууллага" : "Хүргэх хаяг"}</h2>
            {addressError ? <div className="error-msg">{addressError}</div> : null}

            {addressOptions.length > 0 ?
              <div>
                <Select placeholder='Хаяг' search options={addressOptions} value={selectedAddressValue} onChange={onAddressChange} />
              </div>
              :
              <React.Fragment>
                <AddressComponent address={newAddress} onChange={onNewAddressChange}></AddressComponent>
                <div className="filter-body ui checkbox">
                  <input type="checkbox" id="save-address" onChange={(e) => onAddressSaveChange(e)} checked={saveAddress} />
                  <label htmlFor="save-address">Хаяг хадгалах</label>
                </div>
              </React.Fragment>
            }
          </div>
        </div>

        {errorMsg ? <div className="error-msg"><Icon name="warning circle" />{errorMsg}</div> : null}

        {showPayLater ? <React.Fragment>
          <div className="check-out-line">
            <div className="checkout-continue">
              <Button className="width-100" color="red" onClick={() => continueAction(true)}
                disabled={submitting || props.basketItems?.length === 0}>Захиалах (дараа төлбөрт)</Button>
            </div>
          </div>
          <hr />
        </React.Fragment>
          : null}

        <div className="check-out-line">
          <div className="checkout-continue">
            <Button className="width-100" color="red" onClick={() => continueAction(false)}
              disabled={submitting || props.basketItems?.length === 0}>Захиалах {showPayLater ? "(дансаар төлөх)" : ""}</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(DeliveryPage)