import React, { useState, useEffect } from 'react';
import './company.scss';
import { Form, Button, Container, Dimmer, Loader, Icon } from 'semantic-ui-react';
import { CompanyModel } from '../../entities/company.model';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import store from '../../store';

const mapStateToProps = (state: any) => {
    return {
      client: state.client
    }
}

function CompanyDetailsPage(props: any) {

    const history = useHistory()

    useEffect(() => {
        var token = localStorage.getItem('clientToken')
        if (token && token !== 'undefined') {
            getCompanyDetails();
        }
        else {
            console.log("Will redirect to: " + window.location.pathname)
            store.dispatch({ type: "redirectUrl", data: window.location.pathname })
            history.push("/")
        }
    }, []);

    useEffect(() => {
        if (props?.client?.role === 'MANAGER') {
            setManager(true)
        }
    }, [props.client]);

    const [editing, setEditing] = useState(false);
    const [company, setCompany] = useState(new CompanyModel());
    const [fetchedDetails, setFetchedDetails] = useState(false);
    const [companyFound, setCompanyFound] = useState(false);
    const [isManager, setManager] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [originalCompany, setOriginalCompany] = useState(new CompanyModel());

    const changeHandler = (e: any) => {
        setCompany({ ...company, [e.target.name]: e.target.value })
    }

    const getCompanyDetails = () => {
        var token = localStorage.getItem('clientToken')
        if (!token || token === 'undefined') {
            console.log("Will redirect to: " + window.location.pathname)
            store.dispatch({ type: "redirectUrl", data: window.location.pathname })
            history.push("/login")
            return;
        }

        fetch(process.env.REACT_APP_CHEF_API + 'client/company', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': token,
                'Content-Type': 'application/json'
            }
        }).then((res: any) => {
            if (res.ok) {
                res.json().then((resJson: any) => {
                    if (resJson?.company?.id) {
                        let c = Object.assign(company, resJson.company)
                        setOriginalCompany(c)
                        setCompany(Object.assign(company, resJson.company));
                        setCompanyFound(true)
                    }
                    else {
                        setEditing(true)
                        setManager(true)
                    }
                    setFetchedDetails(true);
                })
            }
        }).catch(err => {
            console.error(err)
        })
    }

    const save = () => {
        if (!validate()) {
            return;
        }

        var token = localStorage.getItem('clientToken')
        if (!token || token === 'undefined') {
            return;
        }

        setLoading(true);

        let payload = company;
        delete payload.id;
        delete payload.approved;
        delete payload.active;
        delete payload.canPayLater;
        console.log(payload)

        fetch(process.env.REACT_APP_CHEF_API + 'client/company', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        }).then((res: any) => {
            setLoading(false);
            if (res.ok) {
                setEditing(false)
                setErrorMsg("");
                getCompanyDetails();
                setCompanyFound(true)
                window.scrollTo(0, 100)
            }
            else {
                setErrorMsg("хадгалахад алдаа гарлаа")
                window.scrollTo(0, 100)
            }
        }).catch(err => {
            setLoading(false);
            setErrorMsg("Сүлжээний алдаа")
            window.scrollTo(0, 100)
            console.error(err)
        })
    }

    const validate = (): boolean => {
        if (!company.name) {
            setErrorMsg("Байгууллагын нэр оруулна уу");
            window.scrollTo(0, 100)
            return false;
        }
        if (!company.town) {
            setErrorMsg("Хот оруулна уу");
            window.scrollTo(0, 100)
            return false;
        }
        if (!company.district) {
            setErrorMsg("Дүүрэг оруулна уу");
            window.scrollTo(0, 100)
            return false;
        }
        if (!company.unit) {
            setErrorMsg("Хороо оруулна уу");
            window.scrollTo(0, 100)
            return false;
        }
        if (!company.street) {
            setErrorMsg("Дэлгэрэнгүй хаяг оруулна уу");
            window.scrollTo(0, 100)
            return false;
        }
        if (company.name === originalCompany.name &&
            company.register === originalCompany.register &&
            company.town === originalCompany.town &&
            company.district === originalCompany.district &&
            company.unit === originalCompany.unit &&
            company.street === originalCompany.street &&
            company.zipcode === originalCompany.zipcode
        ) {
            setErrorMsg("Өөрчлөлт байхгүй байна");
            window.scrollTo(0, 100)
            return false;
        }

        return true;
    }

    const discardChanges = () => {
        setEditing(false)
        setCompany(originalCompany)
    }

    return (
        <div className="page margin-top-50-sm">
            <Container>
                <Dimmer active={loading}>
                    <Loader size='medium'></Loader>
                </Dimmer>

                <div className="flex-div width-100">
                    {!fetchedDetails ? null :
                        <section className="company-form slim" id="my-form">

                            {companyFound ?
                                isManager ? <div className="company-controls">
                                    <Link className="link-to" to="/client/company/users">
                                        <span>Байгууллагын хэрэглэгч нар</span>
                                        <Icon name="angle right"></Icon>
                                    </Link>
                                    <Link className="link-to" to="/client/company/orders">
                                        <span>Захиалгууд</span>
                                        <Icon name="angle right"></Icon>
                                    </Link>
                                </div>
                                 : null
                                :
                                <div>
                                    <p>
                                        <Icon name="info circle" />
                                    Та байгууллагын мэдээллээ оруулснаар байгууллагын аккаунт үүсгэх боломжтой.
                                </p>
                                    <p>
                                        <Icon name="info circle" />
                                    Хэрвээ танай байгууллагыг өөр хэрэглэгч бүргүүлсэн байгаа бол та тухайн хэрэглэгчид хандана уу.
                                </p>
                                </div>
                            }

                            <h2>Байгууллагын мэдээлэл</h2>

                            {company.canPayLater ? 
                                <div className="fontMain-yellow">
                                    <Icon name="check circle outline"/>
                                    <span>Та төлбөрийг дараа төлөх нөхцөлтэй захиалга хийх эрхтэй</span>
                                </div> : null
                            }

                            { editing ? 
                                <Form widths='equal'>
                                    { company?.id ? <div className="form-group">
                                        <Button className="width-100" color="red" onClick={() => discardChanges()}>Буцах</Button>
                                    </div> : null }
                                    <div className="form-group">
                                        {errorMsg ? <div className="error-msg">{errorMsg}</div> : null}
                                    </div>
                                    <div className="form-group">
                                        <div className="label">Байгууллагын нэр</div>
                                        <Form.Input type="text" disabled={companyFound && !isManager} required placeholder='Байгууллагын нэр' name="name" value={company.name} onChange={changeHandler} />
                                    </div>
                                    <div className="form-group">
                                        <div className="label">Байгууллагын регистр</div>
                                        <Form.Input type="text" disabled={companyFound && !isManager} placeholder='Регистр' name="register" value={company.register} onChange={changeHandler} />
                                    </div>
                                    <div className="form-group">
                                        <div className="label">Байгууллагын Хаяг</div>
                                    </div>
                                    <div className="form-group">
                                        <div className="label">Хот</div>
                                        <Form.Input type="text" disabled={companyFound && !isManager} required name="town" value={company.town} onChange={changeHandler} />
                                    </div>
                                    <div className="form-group">
                                        <div className="label">Дүүрэг</div>
                                        <Form.Input type="text" disabled={companyFound && !isManager} required name="district" value={company.district} onChange={changeHandler} />
                                    </div>
                                    <div className="form-group">
                                        <div className="label">Хороо</div>
                                        <Form.Input type="text" disabled={companyFound && !isManager} required name="unit" value={company.unit} onChange={changeHandler} />
                                    </div>
                                    <div className="form-group">
                                        <div className="label">Дэлгэрэнгүй</div>
                                        <textarea cols={12} rows={4} disabled={companyFound && !isManager} required name="street" value={company.street} onChange={changeHandler} />
                                    </div>
                                    { !companyFound || isManager ?
                                        <div className="form-group">
                                            <Button className="width-100" color='red' onClick={() => save()} disabled={loading}>Хадгалах</Button>
                                        </div> : null
                                    }
                                </Form>

                                :

                                <div>
                                    <div className="info-group">
                                        <div className="label">Байгууллагын нэр</div>
                                        <div className="value">{company.name}</div>
                                    </div>
                                    <div className="info-group">
                                        <div className="label">Байгууллагын регистр</div>
                                        <div className="value">{company.register}</div>
                                    </div>
                                    <div className="info-group">
                                        <div className="label">Хаяг</div>
                                        <div className="value">{company.town}, {company.district}, {company.unit}, {company.street}</div>
                                    </div>
                                    { isManager ?
                                        <div className="form-group">
                                            <Button className="width-100" color='red' onClick={() => setEditing(true)} disabled={loading}>Өөрчлөх</Button>
                                        </div> : null
                                    }
                                </div>
                            }
                        </section>
                    }
                </div>
            </Container>
        </div>
    );
}

export default connect(mapStateToProps)(CompanyDetailsPage)