import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { Dimmer, Icon, Loader } from 'semantic-ui-react';
import OrderModel from '../../admin/components/order/order.model';
import store from '../../store';
import './order.scss';

function ClientOrderComponent(props: any) {

  const bucket = process.env.REACT_APP_S3_BUCKET
  const region = process.env.REACT_APP_S3_REGION

  const history = useHistory()
  const [order, setOrder] = useState(new OrderModel())
  const [loading, setLoading] = useState(true)
  const [errorMsg, setErrorMsg] = useState("")
  const [fetchedOrder, setFetchedOrder] = useState(false)

  useEffect(() => {
    if (props?.match?.params?.token !== undefined && props?.match?.params?.token !== null) {
      getOrder();
      window.scrollTo(0, 0)
    }
  }, [props.match?.params])

  const getOrder = () => {
    if (fetchedOrder) {
      return
    }
    var token = localStorage.getItem('clientToken')
    if (token && token !== 'undefined') {
      setFetchedOrder(true)
      fetch(process.env.REACT_APP_CHEF_API + 'client/order-detail/' + props.match.params.token, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': token,
          'Content-Type': 'application/json'
        }
      }).then((res: any) => {
        setLoading(false)
        if (res.ok) {
          res.json().then((resJson: any) => {
            let orderObj = resJson.order;
            for (let delivery of orderObj?.deliveries) {
              let date = new Date(delivery.date)
              delivery.date = `${date.getMonth() + 1} сарын ${date.getUTCDate()}`
              for (let item of delivery.items) {
                item.product.imageXsUrl = `https://${bucket}.s3.${region}.amazonaws.com/${item.product.imageXsUrl}`
              }
            }
            setOrder(orderObj)
          })
        }
        else {
          setErrorMsg("Захиалга олдосонгүй")
        }
      }).catch(err => {
        setLoading(false)
        setErrorMsg("Сүлжээний алдаа")
        console.error(err)
      })
    }
    else {
      console.log("Will redirect to: " + window.location.pathname)
      store.dispatch({ type: "redirectUrl", data: window.location.pathname })
      history.push("/login")
    }
  }

  const parseStatus = (status: string) => {
    switch (status) {
      case 'PLACED': return 'ТӨЛӨГДӨӨГҮЙ'
      case 'CONFIRMED': return 'АМЖИЛТТАЙ'
      case 'PAID': return 'БАТАЛГААЖСАН'
      case 'CANCELLED': return 'ЦУЦЛАГДСАН'
      default: return ""
    }
  }

  return (
    <div className="page page-client-order margin-top-50-sm">
      <div className="flex-div width-100">
        <Dimmer active={loading}>
          <Loader size='medium'></Loader>
        </Dimmer>

        {errorMsg ? <div className="width-100 text-center error-msg"><Icon name="warning circle"/>{errorMsg}</div> : null}
        
        { fetchedOrder && order?.id ? 
        <div className="client-order">
          <div className="order-top">
            <div className="order-col order-id fontMain-2">
              <div className="col-top">№</div>
              <div>
                <strong>{order?.token}</strong>
              </div>
            </div>
            <div className="order-col order-amount">
              <div className="col-top">Нийт дүн</div>
              <div>
                <NumberFormat displayType={'text'} value={order?.totalAmount} suffix="₮" thousandSeparator={true} />
              </div>
            </div>
            <div className="order-col">
              <div className="col-top">Төлөв</div>
              <div className={`order-status ${String(order.status || '').toLowerCase()}`}>{parseStatus(order.status)}</div>
            </div>
          </div>

          <div className="order-items">
            <div className="order-address">
              <strong className="col-top">Хаяг: </strong>
              <span>{order.town}, {order.district}, {order.unit}, {order.street}</span>
            </div>

            {order.deliveries?.map((delivery: any, dindex: number) => {
              return <div className="order-delivery" key={'delivery-' + delivery.id}>
                <div className="order-delivery-top">
                  <span className="delivery-date">
                    <Icon name="calendar outline" />{delivery?.date} &nbsp;
                  </span>
                  <span className="delivery-cost"> - Хүргэлтийн үнэ: - <NumberFormat displayType="text" value={delivery?.cost} thousandSeparator={true} suffix="₮" /></span>
                </div>
                <table>
                  <tbody>
                  {delivery?.items?.map((order: any, index: number) => {
                    return <tr key={`order-${order.id}-${index}`}>
                      <td>
                        <img src={order.product.imageXsUrl} height="50" width="50" />
                      </td>
                      <td>{order.product.name}</td>
                      <td>
                        <NumberFormat displayType="text" value={order.price} suffix="₮" thousandSeparator={true} /> x {order.count}
                        &nbsp;=&nbsp;
                        <NumberFormat displayType="text" value={order.price * order.count} suffix="₮" thousandSeparator={true} />
                      </td>
                    </tr>
                  })}
                  </tbody>
                </table>
              </div>
            })
            }
          </div>
          <div className="order-summary">
            <div>Барааны нийт үнэ: <NumberFormat displayType="text" value={order.totalAmount - order.totalDeliveryCost} suffix="₮" thousandSeparator={true} /></div>
            <div>Хүргэлтийн нийт үнэ: <NumberFormat displayType="text" value={order.totalDeliveryCost} suffix="₮" thousandSeparator={true} /></div>
            <div>Нийт үнэ: <NumberFormat displayType="text" value={order.totalAmount} suffix="₮" thousandSeparator={true} /></div>
          </div>
        </div> : null }
      </div>
    </div>
  );
}

export default ClientOrderComponent;