import React from 'react';
import styled from 'styled-components';
import { AddressModel } from '../../entities/address.model';

interface AddressComponentProps {
    address: AddressModel
    onChange: any
}

const FormGroup = styled.div`
    width: 100%;
    margin-top: 20px;
`;

const Label = styled.div`
    font-weight: 700;
    margin-bottom: 10px;
`;

function AddressComponent(props: AddressComponentProps) {

    const handleChange = (e: any) => {
        let address: AddressModel = props.address
        props.onChange({ ...address, [e.target.name]: e.target.value });
      };
    
    return (
        <div className="address-component-container width-100">
            <FormGroup>
                <Label>Хот</Label>
                <input type="text" required name="town" className="width-100" value={props.address.town} onChange={handleChange} />
            </FormGroup>
            <FormGroup>
                <Label>Дүүрэг</Label>
                <input type="text" required name="district" className="width-100" value={props.address.district} onChange={handleChange} />
            </FormGroup>
            <FormGroup>
                <Label>Хороо</Label>
                <input type="text" required name="unit" className="width-100" value={props.address.unit} onChange={handleChange} />
            </FormGroup>
            <FormGroup>
                <Label>Дэлгэрэнгүй</Label>
                <textarea cols={12} rows={4} required name="street" className="width-100" value={props.address.street} onChange={handleChange} />
            </FormGroup>
        </div>
    );
}

export default AddressComponent