import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteProps, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Icon, Form, Dimmer, Container, Loader, Accordion } from 'semantic-ui-react';
import './account.scss';
import { ClientModel } from '../../entities/client.model';
import store from '../../store';

interface AccountProps {

}

interface AccountState {
  loading: boolean
  errorMsg: string,
  successMsg: string,
  redirect: string,
  password: string,
  passwordMatch: string,
  client: ClientModel
  activeIndex: number
}

const mapStateToProps = (state: any) => {
  return {
    client: state.client
  }
}

const Section = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const MyForm = styled.div`
    width: 300px;
    margin-top: 20px;
`;

const FormGroup = styled.div`
    margin-top: 20px;
`;

const MyLabel = styled.div`
    margin-bottom: 10px;
    font-weight: 600;
`;

const WhiteTitle = styled.div`
    margin-top: 20px;
    font-weight: 600;
`;

class ClientAccountPage extends Component<AccountProps & RouteProps, AccountState> {

  clientToken: any;
  originalClient: any;

  constructor(props: any) {
    super(props);

    this.state = {
      successMsg: "",
      errorMsg: "",
      redirect: "",
      password: "",
      passwordMatch: "",
      loading: false,
      activeIndex: 0,
      client: new ClientModel()
    };

    this.onEmailChange = this.onEmailChange.bind(this);
    this.onPhoneChange = this.onPhoneChange.bind(this);
    this.onOwnNameChange = this.onOwnNameChange.bind(this);
    this.onParentNameChange = this.onParentNameChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onPasswordMatchChange = this.onPasswordMatchChange.bind(this);

    this.onTownChange = this.onTownChange.bind(this);
    this.onDistrictChange = this.onDistrictChange.bind(this);
    this.onAddressUnitChange = this.onAddressUnitChange.bind(this);
    this.onStreetChange = this.onStreetChange.bind(this);
    this.onZipCodeChange = this.onZipCodeChange.bind(this);
  }

  componentDidMount() {
    var token = localStorage.getItem('clientToken')
    if (token && token !== 'undefined') {
      this.clientToken = token
      fetch(process.env.REACT_APP_CHEF_API + 'client', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': token,
            'Content-Type': 'application/json'
        }
      })
      .then((res: any) => {
          if (res.ok) {
              res.json().then((resJson: any) => {
                  let client = resJson.client
                  if (!client.town) client.town = ""
                  if (!client.district) client.district = ""
                  if (!client.unit) client.unit = ""
                  if (!client.street) client.street = ""
                  if (!client.zipcode) client.zipcode = ""

                  this.setState({ client: client })
                  this.originalClient = Object.assign({}, client);
                  store.dispatch({
                      type: 'authenticateClient',
                      client: resJson.client
                  })
              })
          }
      })
      .catch(err => {
        console.error(err)
        this.setState({ redirect: "/" })
      })
    } else {
      console.log("Will redirect to: " + window.location.pathname)
      store.dispatch({ type: "redirectUrl", data: window.location.pathname })
      this.setState({ redirect: "/" })
    }
  }

  onEmailChange(e?: any, v?: any) {
    let client = this.state.client
    client.email = e.target.value
    this.setState({ client: client });
  }

  onPhoneChange(e?: any, v?: any) {
    let client = this.state.client
    client.phone = e.target.value
    this.setState({ client: client });
  }

  onOwnNameChange(e?: any, v?: any) {
    let client = this.state.client
    client.ownName = e.target.value
    this.setState({ client: client });
  }

  onParentNameChange(e?: any, v?: any) {
    let client = this.state.client
    client.parentName = e.target.value
    this.setState({ client: client });
  }

  onPasswordChange(e?: any, v?: any) {
    this.setState({ password: e.target.value });
  }

  onPasswordMatchChange(e?: any, v?: any) {
    this.setState({ passwordMatch: e.target.value });
  }

  onTownChange(e?: any) {
    let client = this.state.client
    client.town = e.target.value
    this.setState({ client: client })
  }

  onDistrictChange(e?: any) {
    let client = this.state.client
    client.district = e.target.value
    this.setState({ client: client })
  }

  onAddressUnitChange(e?: any) {
    let client = this.state.client
    client.unit = e.target.value
    this.setState({ client: client })
  }

  onStreetChange(e?: any) {
    let client = this.state.client
    client.street = e.target.value
    this.setState({ client: client })
  }

  onZipCodeChange(e?: any) {
    let client = this.state.client
    client.zipcode = e.target.value
    this.setState({ client: client })
  }

  validateMyDetails(): boolean {
    this.setState({ successMsg: "" })
    if (!this.state.client.parentName) {
      this.setState({ errorMsg: "Та овогоо оруулна уу" })
      return false;
    }
    if (!this.state.client.ownName) {
      this.setState({ errorMsg: "Та өөрийн нэрээ оруулна уу" })
      return false;
    }
    if (!this.state.client.email) {
      this.setState({ errorMsg: "Та имэйл оруулна уу" })
      return false;
    }
    if (!this.validateEmail()) {
      this.setState({ errorMsg: "Имэйл буруу форматтай байна" })
      return false;
    }
    if (!this.state.client.phone) {
      this.setState({ errorMsg: "Та гар утасны дугаар оруулна уу" })
      return false;
    }
    if (!this.validatePhone()) {
      this.setState({ errorMsg: "Та гар утасны дугаараа шалгаж үзнэ үү" })
      return false;
    }
    if (this.state.client.parentName === this.originalClient.parentName &&
      this.state.client.ownName === this.originalClient.ownName &&
      this.state.client.email === this.originalClient.email &&
      this.state.client.phone === this.originalClient.phone
    ) {
      this.setState({ errorMsg: "Өөрчлөлт байхгүй байна" });
      return false;
    }
    return true;
  }

  validatePasswordChange() {
    this.setState({ successMsg: "" })
    if (!this.state.password) {
      this.setState({ errorMsg: "Та нууц үг оруулна уу" })
      return false;
    }
    if (!this.validatePassword()) {
      this.setState({ errorMsg: "Нууц үг 6 тэмдэгтээс урт байх хэрэгтэй" })
      return false;
    }
    if (this.state.password !== this.state.passwordMatch) {
      this.setState({ errorMsg: "Дахин оруулсан нууц үг өөр байна" })
      return false;
    }
    return true;
  }

  validateAddress() {
    this.setState({ successMsg: "" })
    if (!this.state.client.town) {
      this.setState({ errorMsg: "Хот оруулна уу" });
      return false;
    }
    if (!this.state.client.district) {
        this.setState({ errorMsg: "Дүүрэг оруулна уу" });
        return false;
    }
    if (!this.state.client.unit) {
        this.setState({ errorMsg: "Хороо оруулна уу" });
        return false;
    }
    if (!this.state.client.street) {
        this.setState({ errorMsg: "Дэлгэрэнгүй хаяг оруулна уу" });
        return false;
    }
    if (this.state.client.town === this.originalClient.town &&
        this.state.client.district === this.originalClient.district &&
        this.state.client.unit === this.originalClient.unit &&
        this.state.client.street === this.originalClient.street &&
        this.state.client.zipcode === this.originalClient.zipcode
    ) {
      this.setState({ errorMsg: "Өөрчлөлт байхгүй байна" });
      return false;
    }
    return true;
  }

  validateEmail() {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(this.state.client.email).toLowerCase());
  }

  validatePhone() {
    var re = /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/;
    return re.test(String(this.state.client.phone).toLowerCase());
  }

  validatePassword() {
    return this.state.password.length > 6;
  }

  handleClick = (e: any, titleProps: any) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
    this.scrollUp()
  }

  saveMyDetails = () => {
    if (!this.validateMyDetails()) {
      this.scrollUp();
      return;
    }
    this.postClient({
      ownName: this.state.client.ownName,
      parentName: this.state.client.parentName,
      email: this.state.client.email,
      phone: this.state.client.phone
    })
  };

  saveAddress = () => {
    if (!this.validateAddress()) {
      this.scrollUp();
      return;
    }
    this.postClient({
      town: this.state.client.town,
      district: this.state.client.district,
      unit: this.state.client.unit,
      street: this.state.client.street,
      zipcode: this.state.client.zipcode
    })
  }

  postClient(data: any) {
    this.setState({ errorMsg: "", loading: true })
    fetch(process.env.REACT_APP_CHEF_API + 'client', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': this.clientToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(res => {
      if (!res.ok) {
        res.json().then(resJson => {
          this.setState({ errorMsg: resJson.errorMsg, loading: false })
        })
      } else {
        this.setState({ errorMsg: "", successMsg: "Амжилттай хадгаллаа", loading: false })
        this.originalClient = Object.assign({}, this.state.client);
        store.dispatch({
          type: 'authenticateClient',
          client: this.state.client
        })
        var me = this;
        setTimeout(
          function() {
              me.setState({ successMsg: "" });
          }.bind(this), 3000
        )
        this.scrollUp()
      }
    })
    .catch(err => {
      this.setState({ errorMsg: "Сүлжээнд алдаа гарлаа", loading: false })
      this.scrollUp()
    })
  }

  changePassword = () => {
    if (!this.validatePasswordChange()) {
      this.scrollUp();
      return;
    }
    this.setState({ errorMsg: "", loading: true })
    fetch(process.env.REACT_APP_CHEF_API + 'new-password', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': this.clientToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        newPassword: this.state.password
      })
    })
    .then(res => {
      if (!res.ok) {
        res.json().then(resJson => {
          this.setState({ errorMsg: resJson.errorMsg, loading: false })
        })
      } else {
        this.setState({
          errorMsg: "",
          successMsg: "Шинэ нууц үг хадгалагдлаа",
          loading: false,
          password: "",
          passwordMatch: ""
        })
        var me = this;
        setTimeout(
          function() {
              me.setState({ successMsg: "" });
          }.bind(this), 3000
        )
        this.scrollUp();
      }
    })
    .catch(err => {
      this.setState({ errorMsg: "Сүлжээнд алдаа гарлаа", loading: false })
      this.scrollUp();
    })
  }

  scrollUp() {
    let element = document.getElementById("my-form");
    if (element) {
        element.scrollIntoView();
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <div className="page margin-top-50-sm">
        <Container>
          <Section>
            <Dimmer active={this.state.loading}>
              <Loader size='medium'></Loader>
            </Dimmer>
            <MyForm id="my-form">
              <h3>Миний аккаунт</h3>
              { this.state.errorMsg ?
              <Form.Group>
                <div className="error-msg">
                  <Icon name="warning sign" color='red'/>
                  {this.state.errorMsg}
                </div>
              </Form.Group> : null
              }
              { this.state.successMsg ?
              <Form.Group>
                <div className="success-msg">
                  <Icon name="shield alternate" color='green'/>
                  {this.state.successMsg}
                </div>
              </Form.Group> : null
              }
              <Accordion fluid>
                <Accordion.Title index={0} active={this.state.activeIndex === 0} onClick={this.handleClick}>
                  <WhiteTitle>
                    <Icon name='dropdown'/>
                    <span>Миний мэдээлэл</span>
                  </WhiteTitle>
                </Accordion.Title>
                <Accordion.Content active={this.state.activeIndex === 0}>
                  <Form widths='equal'>
                    <FormGroup>
                      <MyLabel>Имэйл</MyLabel>
                      <input type="email" required placeholder='Имэйл' value={this.state.client.email} onChange={this.onEmailChange} />
                    </FormGroup>
                    <FormGroup>
                      <MyLabel>Гар утас</MyLabel>
                      <input type="text" required placeholder='Гар утас' value={this.state.client.phone} onChange={this.onPhoneChange} />
                    </FormGroup>
                    <FormGroup>
                      <MyLabel>Овог</MyLabel>
                      <Form.Input type="text" required placeholder='Овог' value={this.state.client.parentName} onChange={this.onParentNameChange} />
                    </FormGroup>
                    <FormGroup>
                      <MyLabel>Нэр</MyLabel>
                      <input type="text" required placeholder='Нэр' value={this.state.client.ownName} onChange={this.onOwnNameChange} />
                    </FormGroup>
                    <FormGroup>
                      <Button className="width-100" color='red' onClick={() => this.saveMyDetails()} disabled={this.state.loading}>Хадгалах</Button>
                    </FormGroup>
                    <FormGroup></FormGroup>
                  </Form>
                </Accordion.Content>
              </Accordion>

              <Accordion fluid>
                <Accordion.Title index={1} active={this.state.activeIndex === 1} onClick={this.handleClick}>
                  <WhiteTitle>
                    <Icon name='dropdown'/>
                    <span>Нууц үг өөрчлөх</span>
                  </WhiteTitle>
                </Accordion.Title>
                <Accordion.Content active={this.state.activeIndex === 1}>
                  <Form widths='equal'>
                    <FormGroup>
                      <p className="sm-grey-text">Нууц үг 6 тэмдэгтээс урт байх хэрэгтэй</p>
                      <input type="password" required placeholder='Шинэ нууц үг' value={this.state.password} onChange={this.onPasswordChange} />
                    </FormGroup>
                    <FormGroup>
                      <input type="password" required placeholder='Нууц үг дахин оруулна уу' value={this.state.passwordMatch} onChange={this.onPasswordMatchChange} />
                    </FormGroup>
                    <FormGroup>
                      <Button className="width-100" color='red' onClick={() => this.changePassword()} disabled={this.state.loading}>Нууц үг солих</Button>
                    </FormGroup>
                    <FormGroup></FormGroup>
                  </Form>
                </Accordion.Content>
              </Accordion>

              <Accordion fluid>
                <Accordion.Title index={2} active={this.state.activeIndex === 2} onClick={this.handleClick}>
                  <WhiteTitle>
                    <Icon name='dropdown'/>
                    <span>Миний хаяг</span>
                  </WhiteTitle>
                </Accordion.Title>
                <Accordion.Content active={this.state.activeIndex === 2}>
                  <Form widths='equal'>
                    <FormGroup>
                      <MyLabel>Хот</MyLabel>
                      <Form.Input type="text" required value={this.state.client.town} onChange={this.onTownChange} />
                    </FormGroup>
                    <FormGroup>
                      <MyLabel>Дүүрэг</MyLabel>
                      <input type="text" required value={this.state.client.district} onChange={this.onDistrictChange} />
                    </FormGroup>
                    <FormGroup>
                      <MyLabel>Хороо</MyLabel>
                      <input type="text" required value={this.state.client.unit} onChange={this.onAddressUnitChange} />
                    </FormGroup>
                    <FormGroup>
                      <MyLabel>Дэлгэрэнгүй</MyLabel>
                      <textarea cols={12} rows={4} required value={this.state.client.street} onChange={this.onStreetChange} />
                    </FormGroup>
                    <FormGroup>
                      <Button className="width-100" color='red' onClick={() => this.saveAddress()} disabled={this.state.loading}>Хадгалах</Button>
                    </FormGroup>
                    <FormGroup></FormGroup>
                  </Form>
                </Accordion.Content>
              </Accordion>
            </MyForm>
          </Section>
        </Container>
      </div>
    );
  }
}

export default connect(mapStateToProps)(ClientAccountPage);