import * as React from "react";
import styled from 'styled-components';
import { Form, Button, Icon } from "semantic-ui-react";

interface ForgotProps {

}

interface ForgotState {
  successMsg: string,
  errorMsg: string,
  email: string,
  loading: boolean
}

const Section = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const MyForm = styled.div`
    width: 350px;
    margin-top: 20px;
`;

const FormGroup = styled.div`
    margin-top: 20px;
    box-sizing: border-box;
`;

// create a password schema
class ProviderForgotPasswordComponent extends React.Component<ForgotProps, ForgotState> {
  constructor(props: any) {
    super(props);

    this.state = {
      successMsg: "",
      errorMsg: "",
      email: "",
      loading: false
    };

    this.onEmailChange = this.onEmailChange.bind(this);
  }

  onEmailChange(e?: any, v?: any) {
    this.setState({ email: e.target.value });
  }

  validate(): boolean {
    if (!this.state.email) {
      this.setState({ errorMsg: "Та имэйл оруулна уу" })
      return false;
    }
    if (!this.validateEmail()) {
      this.setState({ errorMsg: "Имэйл буруу форматтай байна" })
      return false;
    }
    return true;
  }

  validateEmail() {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(this.state.email).toLowerCase());
  }

  handleSubmit = () => {
    if (!this.validate()) {
      return;
    }

    this.setState({ errorMsg: "", loading: true })

    fetch(process.env.REACT_APP_CHEF_API + 'provider-forgot-password', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: this.state.email })
    })
      .then(res => {
        if (!res.ok) {
          res.json().then(resJson => {
            this.setState({ errorMsg: resJson.errorMsg, loading: false })
          })
        } else {
          this.setState({ successMsg: "Нууц үг өөрчлөх линкийг имэйлээр явуулсан тул имэйлээ шалгана уу (inbox-д байхгүй бол spam-аа шалгана уу)", loading: false })
        }
      })
      .catch(err => {
        console.error(err)
        this.setState({ errorMsg: "Сүлжээнд алдаа гарлаа", loading: false })
      })
  };

  scrollToForm = () => {
    let element = document.getElementById("signup-form");
    if (element) {
      element.scrollIntoView();
    }
  }

  render() {
    return (
      <div className="page signup-page margin-top-50">
        <Section>
          <MyForm>
            <Form id="signup-form">
              { this.state.successMsg ?
                <FormGroup>
                  <Icon name="mail outline" color='green' />
                  {this.state.successMsg}
                </FormGroup>
                :
                <React.Fragment>
                  {this.state.errorMsg ? <React.Fragment>
                    <br />
                    <FormGroup>
                      <Icon name="warning sign" color='red' />
                      {this.state.errorMsg}
                    </FormGroup>
                  </React.Fragment> : null
                  }

                  <FormGroup>
                    <input type="email" required placeholder='Имэйл' value={this.state.email} onChange={this.onEmailChange} />
                  </FormGroup>

                  <br />
                  <Button className="width-100" color='red' onClick={this.handleSubmit}>Нууц үг өөрчлөх</Button>
                </React.Fragment>
              }
            </Form>
          </MyForm>
        </Section>
      </div>
    );
  }
}

export default ProviderForgotPasswordComponent;