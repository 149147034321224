import React, { useEffect, useState } from 'react';
import './product-page.scss';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-grid-system';
import TopProducts from '../top-products/top-products';
import store from '../../store';
import { Button, Icon, Dimmer, Loader } from 'semantic-ui-react';
import TopProductItem from '../top-product-item/top-product';
import { ClientProductModel } from '../../entities/product.model';
import NumberFormat from 'react-number-format';

interface ProductProps {
  product: ClientProductModel,
  brand: any,
  category: any,
  subCategory: any,
  clientLoggedIn: boolean,
  savedProduct: boolean,
  settings: any,
  deliveryDateMin: any,
  deliveryDateMax: any,
  deliveryTime: any,
  categoryProducts: any[]
}

const mapStateToProps = (state: any, props: any) => {
  let product: any = {};
  let brand;
  let category: any = {};
  let subCategory;
  let basketCount = 0;

  for (let p of state.products) {
    if (p.slug == props.match.params.slug) {
      product = p;
      if (state.basket && state.basket.quantityById) {
        if (state.basket.quantityById[p.id] !== null && state.basket.quantityById[p.id] !== undefined) {
          // exists in the basket
          basketCount = state.basket.quantityById[product.id];
        }
      }
      break;
    }
  }

  if (!product) return {};

  for (let b of state.brands) {
    if (b.id == product.brandId) {
      brand = b;
      break;
    }
  }

  for (let c of state.categories) {
    if (c.id == product.categoryId) {
      category = c;
    }

    for (let s of c.subCategories) {
      if (s.id == product.subCategoryId) {
        subCategory = s;
      }
    }
  }

  let clientLoggedIn = false;
  let savedProduct = false;

  if (state.client && state.client.id) {
    clientLoggedIn = true;
    if (product && state.client && state.client.savedProducts) {
      for (let p of state.client.savedProducts) {
        if (p.productId === product.id) {
          savedProduct = true
        }
      }
    }
  }

  let deliveryDateMin;
  let deliveryDateMax;

  if (product.deliveryDayMargin && state.settings?.deliveryMaxDays) {
    let date = new Date();
    date.setDate(date.getDate() + product.deliveryDayMargin)
    deliveryDateMin = `${date.getMonth() + 1} сарын ${date.getUTCDate()}`
    date.setDate(date.getDate() + state.settings.deliveryMaxDays)
    deliveryDateMax = `${date.getMonth() + 1} сарын ${date.getUTCDate()}`
  }

  return {
    product: product,
    category: category,
    subCategory: subCategory,
    brand: brand,
    categoryProducts: state.products?.filter((p: any) => p.id !== product?.id && p.active && p.brandActive && p.categoryActive && p.categoryId === category?.id && p.warehouseCount > 0),
    clientLoggedIn: clientLoggedIn,
    savedProduct: savedProduct,
    settings: state.settings,
    deliveryDateMin: deliveryDateMin,
    deliveryDateMax: deliveryDateMax,
    deliveryTime: state.settings.deliveryTime,
    basketCount: basketCount | 0,
  };
}

function ProductPage(props: any) {

  const [ savedLoading, setSavedLoading ] = useState(false)
  const [ countText, setCountText ] = useState("1")
  const [ showBasketMsg, setShowBasketMsg ] = useState(false)
  const [ showMaxStock, setShowMaxStock ] = useState(false)
  const [ scrolledUp, setScrolledUp ] = useState(false)

  useEffect(() => {
    if (!scrolledUp) {
      let element = document.getElementById("product-page");
        if (element) {
          element.scrollIntoView();
          setScrolledUp(true)
        }
        else {
          window.scrollTo(0, 100);
          setScrolledUp(true)
        }
    }
    if (!props?.basketCount || props?.basketCount < props?.product?.warehouseCount) {
      setShowBasketMsg(false)
      // setShowMaxStock(false)
    }
  }, [props]);

  const onCountTextChange = (e?: any) => {
    setCountText(e.target.value || "")
    if (!e.target.value) {
      return;
    }
    // stock message
    let count: number = Number(e.target.value);
    // basket message
    setShowBasketMsg(props.product?.warehouseCount <= props.basketCount)
    setShowMaxStock(props.product?.warehouseCount <= props.basketCount + count)
  }

  const addToBasket = () => {
    if (!countText) {
      // empty
      return;
    }
    let count: number = Number(countText);
    if (isNaN(count)) {
      // not a number
      return;
    }
    // basket message
    setShowBasketMsg(props.product?.warehouseCount <= props.basketCount)
    // stock message
    setShowMaxStock(props.product?.warehouseCount <= props.basketCount + count)
    if (props.product?.warehouseCount < props.basketCount + count) {
      // not enough stock
      return;
    }
    // add to basket
    store.dispatch({
      type: 'addToBasket',
      product: props.product,
      count: count
    })
  }

  const toggleSavedProduct = () => {
    let token = localStorage.getItem('clientToken')
    if (token && token !== undefined) {
      if (props.savedProduct) {
        setSavedLoading(true)
        // remove from the saved products
        fetch(process.env.REACT_APP_CHEF_API + 'client/product/' + props.product.id, {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Authorization': token,
            'Content-Type': 'application/json'
          }
        })
          .then((res: any) => {
            setSavedLoading(false)
            if (res.ok) {
              store.dispatch({
                type: 'removeSavedProduct',
                data: props.product.id
              })
            }
          })
          .catch((err: any) => {
            setSavedLoading(false)
            console.error(err)
          })
      }
      else {
        setSavedLoading(true)
        // add to the saved products
        fetch(process.env.REACT_APP_CHEF_API + 'client/product', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Authorization': token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            productId: props.product.id
          })
        })
          .then((res: any) => {
            setSavedLoading(false)
            if (res.ok) {
              store.dispatch({
                type: 'addSavedProduct',
                data: props.product.id
              })
            }
          })
          .catch((err: any) => {
            setSavedLoading(false)
            console.error(err)
          })
      }
    }
  }

  return (<React.Fragment>
    { props.product && props.category ?
      <div id="product-page" className="page product-page">

        {/* Mobile screen */}
        <div id="mobile-container" className="mobile-container">
          <div className="breadcrumb">
            <Link to={"/category/" + props.category?.slug} className="category">{props.category?.name}</Link>
            {(props.subCategory && props.subCategory.id) ?
              <React.Fragment>
                &nbsp;<Icon name="angle right"></Icon>
                <Link to={"/category/" + props.category?.id + "/" + props.subCategory?.id} className="sub-category">{props.subCategory?.name}</Link>
              </React.Fragment> : null
            }
            {(props.brand && props.brand.id) ?
              <React.Fragment>
                &nbsp;<Icon name="angle right"></Icon>
                <Link to={"/brand/" + props.brand?.slug} className="brand">{props.brand?.name}</Link>
              </React.Fragment> : null
            }
          </div>

          <h2>{props.product?.name}</h2>

          <div className="product-section flex-div">
            <img className="main-product-image" src={props.product.imageSmUrl} />
          </div>

          <div className="product-price-line">
            {props.product.saleActive ?
              <React.Fragment>
                <div className="price">
                  <NumberFormat displayType={'text'} value={props.product.salePrice} suffix="₮" thousandSeparator={true} />
                  { props.product.size && props.product.unit ? <span className="price-per">&nbsp;/&nbsp;{props.product.size + " " + props.product.unit}</span> : null }
                </div>
                <div className="price striked">
                  <NumberFormat displayType={'text'} value={props.product.price} suffix="₮" thousandSeparator={true} />
                  { props.product.size && props.product.unit ?  <span className="price-per">&nbsp;/&nbsp;{props.product.size + " " + props.product.unit}</span> : null }
                </div>
              </React.Fragment> :
              <React.Fragment>
                <span className="price">
                  <NumberFormat displayType={'text'} value={props.product.price} suffix="₮" thousandSeparator={true} />
                </span>
                &nbsp;/&nbsp;
                { props.product.size && props.product.unit ? <span className="price-per">{props.product.size + " " + props.product.unit}</span> : null }
              </React.Fragment>
            }
          </div>

          { !showBasketMsg && props.basketCount > 0 ? <div className="basket-count">{`Сагсанд энэ бүтээгдэхүүн ${props.basketCount} байна`}</div> : null }
          { showBasketMsg ? <div className="warehouse-line">Сагсанд бүх боломжит үлдэгдэл нэмэгдсэн байна</div> : null }
          { showMaxStock ? <div className="warehouse-line">{"Нийт боломжит үлдэгдэл: " + props.product.warehouseCount}</div> : null }
          { showMaxStock && props.product?.warehouseCount - props?.basketCount > 0? <div className="basket-msg">{`Сагсанд дахин ${props.product?.warehouseCount - props?.basketCount}-г нэмэх боломжтой`}</div> : null }
          { props.product.warehouseCount === 0 ? <div className="warehouse-line">Үлдэгдэл байхгүй</div> : null }

          <div className="basket-line">
            <input type="number" value={countText} onChange={onCountTextChange} />
            <Button disabled={props.product.warehouseCount === 0} className="btn-basket ui button" onClick={() => addToBasket()}>Сагслах</Button>
          </div>

          {
            props.clientLoggedIn ?
              <div className="save-line">
                <Button className="ui button" color="grey" disabled={savedLoading} onClick={() => toggleSavedProduct()}>
                { savedLoading ? <Loader active={true} inverted size="small"></Loader> : props.savedProduct ?
                    <span className="cursor">
                      <Icon name="heart" color='red' />&nbsp;&nbsp;
                      <span className="text">Хадгалсан</span>
                    </span> :
                    <span className="cursor">
                      <Icon name="heart" />&nbsp;&nbsp;
                      <span className="text">Хадгалах</span>
                    </span>
                }
                </Button>
              </div> : null
          }

          {props.deliveryDateMax ?
            <div className="delivery-line">
              <Icon name="truck" size="large">&nbsp;</Icon>
              <span className="text">{`${props.deliveryDateMin}-с ${props.deliveryDateMax}-ны хооронд ${props.deliveryTime} цагын хооронд хүргэх боломжтой.`}</span>
            </div> : null
          }

          <div className="product-section product-detail-images">
            {props.product.image1 ?
              <div className="product-detail-image">
                <img className="detail-image" src={props.product.image1} />
              </div> : null}
            {props.product.image2 ?
              <div className="product-detail-image">
                <br /><br />
                <img className="detail-image" src={props.product.image2} />
              </div> : null}
            {props.product.image3 ?
              <div className="product-detail-image">
                <br /><br />
                <img className="detail-image" src={props.product.image3} />
              </div> : null}
          </div>

          <div className="brand-line">
            <Link to={"/brand/" + props.brand?.slug}>
              <div className="flex-div">
                <img className="brand-image" src={props.brand?.imageXsUrl} />
              </div>
              <div>{props.brand?.name} брэндийн бусад бүтээгдэхүүнүүд</div>
            </Link>
          </div>

          <div className="product-section top-products-section">
            { props.categoryProducts?.length > 0 ?
            <TopProducts title="Санал болгох бүтээгдэхүүн" products={props.categoryProducts.map((i: any) => <TopProductItem product={i} />)} />
            : null }
          </div>
        </div>

        {/* Desktop and Tablet */}
        <Container fluid={true} className="product-page-container" id="product-page-container">
          <Row>
            <Col className="breadcrumb">
              <Link to={"/category/" + props.category?.slug} className="category">{props.category?.name}</Link>
              {(props.subCategory && props.subCategory.id) ?
                <React.Fragment>
                  &nbsp;<Icon name="angle right"></Icon>
                  <Link to={"/category/" + props.category?.id + "/" + props.subCategory?.id} className="sub-category">{props.subCategory?.name}</Link>
                </React.Fragment> : null
              }
              {(props.brand && props.brand.id) ?
                <React.Fragment>
                  &nbsp;<Icon name="angle right"></Icon>
                  <Link to={"/brand/" + props.brand?.slug} className="brand">{props.brand?.name}</Link>
                </React.Fragment> : null
              }
            </Col>
          </Row>

          <Row>
            <Col>
              <h2>{props.product?.name}</h2>
            </Col>
          </Row>

          <Row>
            <Col xs={7} sm={7} md={9} className="product-left">
              <div className="product">
                <div className="product-section flex-div">
                  <img className="main-product-image" src={props.product.imageLgUrl} />
                </div>

                <div className="product-section top-products-section">
                  { props.categoryProducts?.length > 0 ?
                  <TopProducts title="Санал болгох бүтээгдэхүүн" products={props.categoryProducts?.map((i: any) => <TopProductItem product={i} />)} />
                  : null }
                </div>

                <div className="product-section product-detail-images">
                  {props.product.image1 ?
                    <div className="product-detail-image">
                      <img className="detail-image" src={props.product.image1} />
                    </div> : null}
                  {props.product.image2 ?
                    <div className="product-detail-image">
                      <br /><br />
                      <img className="detail-image" src={props.product.image2} />
                    </div> : null}
                  {props.product.image3 ?
                    <div className="product-detail-image">
                      <br /><br />
                      <img className="detail-image" src={props.product.image3} />
                    </div> : null}
                </div>
              </div>
            </Col>

            <Col xs={5} sm={5} md={3} className="product-right">
              <div className="product-right-inner">
                <div className="right-section">
                  <div className="product-price-line">
                    {props.product.saleActive ?
                      <React.Fragment>
                        <div className="price">
                          <NumberFormat displayType={'text'} value={props.product.salePrice} suffix="₮" thousandSeparator={true} />
                          { props.product.size && props.product.unit ? <span className="price-per">&nbsp;/&nbsp;{props.product.size + " " + props.product.unit}</span> : null }
                        </div>
                        <div className="price striked">
                          <NumberFormat displayType={'text'} value={props.product.price} suffix="₮" thousandSeparator={true} />
                          { props.product.size && props.product.unit ? <span className="price-per">&nbsp;/&nbsp;{props.product.size + " " + props.product.unit}</span> : null }
                        </div>
                      </React.Fragment> :
                      <React.Fragment>
                        <span className="price">
                          <NumberFormat displayType={'text'} value={props.product.price} suffix="₮" thousandSeparator={true} />
                        </span>
                        { props.product.size && props.product.unit ? <span className="price-per">&nbsp;/&nbsp;{props.product.size + " " + props.product.unit}</span> : null }
                      </React.Fragment>
                    }

                  </div>

                  { !showBasketMsg && props.basketCount > 0 ? <div className="basket-count">{`Сагсанд энэ бүтээгдэхүүн ${props.basketCount} байна`}</div> : null }
                  { showBasketMsg ? <div className="warehouse-line">Сагсанд бүх боломжит үлдэгдэл нэмэгдсэн байна</div> : null }
                  { showMaxStock ? <div className="warehouse-line">{"Нийт боломжит үлдэгдэл: " + props.product.warehouseCount}</div> : null }
                  { showMaxStock && props.product?.warehouseCount - props?.basketCount > 0? <div className="basket-msg">{`Сагсанд дахин ${props.product?.warehouseCount - props?.basketCount}-г нэмэх боломжтой`}</div> : null }
                  { props.product.warehouseCount === 0 ? <div className="warehouse-line">Үлдэгдэл байхгүй</div> : null }

                  <div className="basket-line">
                    <input type="text" value={countText} onChange={onCountTextChange}/>
                    <Button disabled={props.product.warehouseCount === 0} className="btn-basket ui button" onClick={() => addToBasket()}>Сагслах</Button>
                  </div>
                </div>

                {
                  props.clientLoggedIn ?
                    <div className="save-line">
                      <Button className="ui button" color="grey" disabled={savedLoading} onClick={() => toggleSavedProduct()}>
                      { savedLoading ? <Loader active={true} inverted size="small" color="white"/> : props.savedProduct ?
                          <span className="cursor">
                            <Icon name="heart" color='red' />&nbsp;&nbsp;
                            <span className="text">Хадгалсан</span>
                          </span> :
                          <span className="cursor">
                            <Icon name="heart" />&nbsp;&nbsp;
                            <span className="text">Хадгалах</span>
                          </span>
                      }
                      </Button>
                    </div> : null
                }

                {props.deliveryDateMax ?
                  <div className="delivery-line">
                    <Icon name="truck" size="large">&nbsp;</Icon>
                    <span className="text">{`${props.deliveryDateMin}-с ${props.deliveryDateMax}-ны хооронд ${props.deliveryTime} цагын хооронд хүргэх боломжтой.`}</span>
                  </div> : null
                }

                <div className="brand-line">
                  <Link to={"/brand/" + props.brand?.slug}>
                    <img className="brand-image" src={props.brand?.imageXsUrl} />
                    <span>{props.brand?.name} брэндийн бусад бүтээгдэхүүнүүд</span>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>

        </Container>
      </div> :
      <Dimmer active={true}>
        <Loader size='medium'></Loader>
      </Dimmer>
    }
  </React.Fragment>
  )
}

export default connect(mapStateToProps)(ProductPage)