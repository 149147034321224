import React, { Component } from 'react';
import './products.scss';
import ProductItem from '../product-item/product-item';
import { Col } from 'react-grid-system';
import ProductsFilter from './filter';
import { ProductModel } from '../../entities/admin.product.model';
import { Dropdown } from 'semantic-ui-react';

interface ProductsProps {
  products: ProductModel[]
}

interface ProductsState {
  filteredProducts: ProductModel[]
  filtersOpen: boolean
  sale: boolean
  searchValue: string
  // parameters
  priceMin: number
  priceMax: number
  priceDown: number
  priceUp: number
  unitMap: Map<string, any>
  brandSet: string[]
  usageSet: string[]
  typeSet: string[],
  sortOptions: any[]
  sortValue: string
}

class Products extends Component<ProductsProps, ProductsState> {

  unitValue: any = { unit: "", min: 0, max: 0 };
  filteredBrands: Set<String> = new Set();
  filteredUsages: Set<String> = new Set();
  filteredTypes: Set<String> = new Set();

  constructor(props: any) {
    super(props);
    this.state = {
      filteredProducts: [],
      sale: false,
      searchValue: "",
      filtersOpen: false,
      priceMin: 0,
      priceMax: 0,
      priceUp: 0,
      priceDown: 0,
      unitMap: new Map<string, any>(),
      brandSet: [],
      usageSet: [],
      typeSet: [],
      sortOptions: [
        { key: "lastfirst", value: "lastfirst", text: "Сүүлд нэмэгдсэн"},
        { key: "priceup", value: "priceup", text: "Үнэ - багаас их"},
        { key: "pricedown", value: "pricedown", text: "Үнэ - ихээс бага"},
        { key: "saleup", value: "saleup", text: "Хямдарсан"},
      ],
      sortValue: "lastfirst",
    }

    this.onSortChange = this.onSortChange.bind(this);
  }

  componentDidMount() {
    let sale = this.isSaleFilterActive()
    let searchValue = decodeURI(this.hasSearchValue() || "")
    let products = this.props.products;
    let querySortValue = this.isSortQueryActive()
    this.setState({ filteredProducts: products, sale: sale, searchValue: searchValue, sortValue: querySortValue || "lastfirst" }, () => {
      this.applyFilter()
    })
    this.prepareFilters(this.props)
  }

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.products) {
      let products: ProductModel[] = [] 
      for (let p of nextProps.products) {
        if (!p.active) continue;
        products.push(p)
      }
      let sale = this.isSaleFilterActive()
      let searchValue = decodeURI(this.hasSearchValue() || "")
      products.sort((a: any, b: any) => {
        if ((a.warehouseCount === 0 && b.warehouseCount === 0) || (a.warehouseCount > 0 && b.warehouseCount > 0)) {
          // id desc
          return (a.id < b.id) ? 1 : -1;
        }
        else {
          // stocked first
          return (a.warehouseCount === 0) ? 1 : -1;
        }
      })
      this.setState({ filteredProducts: products, sale: sale, searchValue: searchValue }, () => {
        this.applyFilter()
      })
    }
    this.prepareFilters(nextProps)
  }

  prepareFilters(props: any) {
    if (props.products && props.products.length > 0) {
      let unitMap = new Map<string, any>();
      let brandSet = new Set<string>();
      let usageSet = new Set<string>();
      let typeSet = new Set<string>();
      let firstProduct = props.products[0];
      let priceMin = firstProduct.saleActive ? firstProduct.salePrice : firstProduct.price
      let priceMax = priceMin
      for(let product of props.products) {
        // price
        let price = product.saleActive ? product.salePrice : product.price
        if (price < priceMin) {
          priceMin = price
        }
        if (price > priceMax) {
          priceMax = price
        }
        // unit/size
        if (unitMap.has(product.unit)) {
          if (product.size < unitMap.get(product.unit).min) {
            unitMap.get(product.unit).min = product.size
          }
          if (product.size > unitMap.get(product.unit).max) {
            unitMap.get(product.unit).max = product.size
          }
        } else {
          unitMap.set(String(product.unit), { min: product.size, max: product.size })
        }
        // brands
        if (!brandSet.has(product.brandName)) {
          brandSet.add(product.brandName)
        }
        // usages
        for (let usage of product.usages) {
          if (!usageSet.has(usage)) {
            usageSet.add(usage)
          }
        }
        // types
        for (let ptype of product.types) {
          if (!typeSet.has(ptype)) {
            typeSet.add(ptype)
          }
        }
      }
      // this.priceValue = { min: priceMin, max: priceMax };
      this.setState({ priceMin: priceMin, priceMax: priceMax, priceDown: priceMin, priceUp: priceMax, unitMap: unitMap,
        brandSet: Array.from(brandSet.values()), usageSet: Array.from(usageSet.values()), typeSet: Array.from(typeSet.values()) })
    }
  }

  onFilterChange(e?: any) {
    if (e) {
      if (e.sale !== undefined) {
        this.setState({ sale: e.sale }, () => this.applyFilter())
      }
      if (e.priceValue !== undefined) {
        this.setState({ priceDown: e.priceValue.min, priceUp: e.priceValue.max })
        this.applyFilter();
      }
      if (e.unitValue !== undefined) {
        this.unitValue = e.unitValue;
        this.applyFilter();
      }
      if (e.brands !== undefined) {
        this.filteredBrands = e.brands;
        this.applyFilter();
      }
      if (e.usages !== undefined) {
        this.filteredUsages = e.usages;
        this.applyFilter();
      }
      if (e.types !== undefined) {
        this.filteredTypes = e.types;
        this.applyFilter();
      }
    }
  }

  applyFilter() {
    let filteredProducts = this.props.products.filter((p: any) => {
      // sale
      if (this.state.sale && !p.saleActive) return false;
      // name
      if (this.state.searchValue) {
        if (String(p.name).toLowerCase().indexOf(this.state.searchValue.toLowerCase()) === -1) {
          return false;
        }
      }
      // price
      let price = p.saleActive ? p.salePrice : p.price;
      if (price < this.state.priceDown || price > this.state.priceUp) return false
      // size/unit
      if (this.unitValue.unit && this.unitValue.unit !== 'Бүгд') {
        if (p.unit !== this.unitValue.unit) return false;
        if (p.size < this.unitValue.min || p.size > this.unitValue.max) return false;
      }
      // brand
      if (this.filteredBrands && this.filteredBrands.size > 0 && !this.filteredBrands.has(p.brandName)) {
        return false;
      }
      // usage
      if (this.filteredUsages && this.filteredUsages.size > 0) {
        if (!p.usages) return false;
        let usageMatches = false;
        this.filteredUsages.forEach(fusage => {
          for (let usage of p.usages) {
            if (fusage === usage) {
              usageMatches = true;
              break;
            }
          }
        })
        if (!usageMatches) return false;
      }
      // type
      if (this.filteredTypes && this.filteredTypes.size > 0) {
        if (!p.types) return false;
        let typeMatches = false;
        this.filteredTypes.forEach(ftype => {
          for (let type of p.types) {
            if (ftype === type) {
              typeMatches = true;
              break;
            }
          }
        })
        if (!typeMatches) return false;
      }
      return true;
    });
    this.sortProducts(filteredProducts, this.state.sortValue)
    this.setState({ filteredProducts: filteredProducts })
  }

  isSaleFilterActive() {
    if (window?.location?.search) {
      let str = String(window?.location?.search)
      let arr: string[] = str.split("?")
      if (arr.length === 0) return false
      let saleStr = arr[1].toLowerCase()
      if (saleStr && saleStr.indexOf("sale=") !== -1) {
          arr = saleStr.split("sale=")
          saleStr = arr[1]
          if (saleStr) {
            arr = saleStr.split("&")
              saleStr = arr[0]
              if (saleStr) {
                  // found sale query param
                  if (saleStr === "1") {
                      return true
                  }
              }
          }
      }
    }
    return false
  }

  isSortQueryActive() {
    if (window?.location?.search) {
      let str = String(window?.location?.search)
      let arr: string[] = str.split("?")
      if (arr.length === 0) return;
      let queryParam = arr[1].toLowerCase()
      if (queryParam && queryParam.indexOf("sort=") !== -1) {
        arr = queryParam.split("sort=")
        queryParam = arr[1]
        if (queryParam) {
          arr = queryParam.split("&")
          queryParam = arr[0]
          if (queryParam) {
            return queryParam;
          }
        }
      }
    }
  }

  hasSearchValue() {
    if (window?.location?.search) {
      let arr = String(window?.location?.search).split("?")
      if (arr.length === 0) return
      let str = arr[1].toLowerCase()
      if (str && str.indexOf("value=") !== -1) {
          arr = str.split("value=")
          str = arr[1]
          if (str) {
            arr = str.split("&")
              str = arr[0]
              if (str) {
                  // found sale query param
                  if (str) {
                      return str
                  }
              }
          }
      }
    }
  }

  onSortChange(e: any, drop: any) {
    if (drop.value !== this.state.sortValue) {
      this.setState({ sortValue: drop.value })
      if (this.state.filteredProducts?.length > 0) {
        this.sortProducts(this.state.filteredProducts, drop.value)
      }
    }
  }

  sortProducts(products: any[], sortValue: string) {
    if (sortValue === "lastfirst") {
      products.sort((a: any, b: any) => {
        if ((a.warehouseCount === 0 && b.warehouseCount === 0) || (a.warehouseCount > 0 && b.warehouseCount > 0)) {
          // id desc
          return (a.id < b.id) ? 1 : -1;
        }
        else {
          // stocked first
          return (a.warehouseCount === 0) ? 1 : -1;
        }
      })
    }
    else if (sortValue === "priceup") {
      products.sort((a: any, b: any) => {
        if ((a.warehouseCount === 0 && b.warehouseCount === 0) || (a.warehouseCount > 0 && b.warehouseCount > 0)) {
          let priceA = a.saleActive ? a.salePrice : a.price;
          let priceB = b.saleActive ? b.salePrice : b.price;
          // price asc
          return (priceA < priceB) ? -1 : 1;
        }
        else {
          // stocked first
          return (a.warehouseCount === 0) ? 1 : -1;
        }
      })
    }
    else if (sortValue === "pricedown") {
      products.sort((a: any, b: any) => {
        if ((a.warehouseCount === 0 && b.warehouseCount === 0) || (a.warehouseCount > 0 && b.warehouseCount > 0)) {
          let priceA = a.saleActive ? a.salePrice : a.price;
          let priceB = b.saleActive ? b.salePrice : b.price;
          // price desc
          return (priceA < priceB) ? 1 : -1;
        }
        else {
          // stocked first
          return (a.warehouseCount === 0) ? 1 : -1;
        }
      })
    }
    else if (sortValue === "saleup") {
      products.sort((a: any, b: any) => {
        if ((a.warehouseCount === 0 && b.warehouseCount === 0) || (a.warehouseCount > 0 && b.warehouseCount > 0)) {
          if (a.saleActive === b.saleActive) {
            let priceA = a.saleActive ? a.salePrice : a.price;
            let priceB = b.saleActive ? b.salePrice : b.price;
            // price asc
            return (priceA < priceB) ? -1 : 1;
          }
          // sale first
          return (a.saleActive) ? -1 : 1;
        }
        else {
          // stocked first
          return (a.warehouseCount === 0) ? 1 : -1;
        }
      })
    }
    return products;
  }
  
  render() {
    return (
        <section className={"products-container bordered"} id="products-content">
          <div className={"products-filter" + (this.state.filtersOpen ? " filter-open" : "")}>
            <div className="filter-section-title">
              <span>Филтер</span>
              <span className="filter-close" onClick={() => this.setState({ filtersOpen: false })}>Хаах</span>
            </div>
            <ProductsFilter
              sale={this.state.sale} 
              unitMap={this.state.unitMap} 
              priceMin={this.state.priceMin} priceMax={this.state.priceMax} 
              priceDown={this.state.priceDown} priceUp={this.state.priceUp} 
              brands={this.state.brandSet} 
              usageSet={this.state.usageSet} 
              typeSet={this.state.typeSet}
              onChange={e => this.onFilterChange(e)} />
          </div>
          <div className="products-content">
            <div className="products-title">
              <Dropdown options={this.state.sortOptions} defaultValue={this.state.sortValue} onChange={this.onSortChange} />
              <span className="filter-button" onClick={() => this.setState({ filtersOpen: true })}>Филтер</span>
            </div>
            <div className="products-body">
              {
              (this.state.filteredProducts && this.state.filteredProducts.length > 0) ?
              this.state.filteredProducts.map((product: any) => {
                return <Col className="product-item-col" xs={6} sm={6} md={4} lg={4} key={`product-${product.id}`}>
                  <ProductItem product={product}/>
                </Col>
              }) : <div className="no-product">Бүтээгдэхүүн олдсонгүй</div>}
            </div>
          </div>
        </section>
    );
  }
}

export default Products;