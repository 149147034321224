import React, { Component } from 'react';
import './featured.scss';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AliceCarousel from 'react-alice-carousel';

interface MyProps {
  featured: []
}

const mapStateToProps = (state: any) => {
  return {
    featured: state.featured
  }
}

class Featured extends Component<MyProps> {

  public Carousel: any;

  responsive = {
    500: { items: 1 }
  }

  render() {
    return (
      // <div className="featured-container">
      //   {this.props?.featured?.slice(0, 3).map((item: any, i) => {
      //     return <Col key={'featured-' + i} xs={4} sm={4} md={4}>
      //       <Link to={item.url}>
      //         <div className="featured-item" style={{ backgroundImage: `url(${item.imageXsUrl})` }}>
      //           <div className="featured-title">{item.info}</div>
      //         </div>
      //       </Link>
      //     </Col>
      //   })}
      // </div>
      <div className="featured-container">
        <AliceCarousel
          ref={(el) => (this.Carousel = el)}
          items={this.props?.featured?.map((item: any) => item.url ? 
            <Link className="banner-url" to={item.url}>
              <img src={window?.innerWidth <= 1000 ? window?.innerWidth <= 600 ? item.imageXsUrl : item.imageSmUrl : item.imageLgUrl}/>
            </Link>
            :
            <div className="banner-url"><img src={item.imageLgUrl}/></div>
            )
          }
          mouseTrackingEnabled={true}
          touchTrackingEnabled={true}
          buttonsDisabled={true}
          dotsDisabled={false}
          infinite={this.props?.featured?.length > 1}
          autoHeight={true}
          autoPlay={true}
          stopAutoPlayOnHover={true}
          duration={1500}
          autoPlayInterval={3000}
          responsive={this.responsive}>
        </AliceCarousel>
    </div>
    );
  }
}

export default connect(mapStateToProps)(Featured);